import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";

import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { GetDemandDecisionDays, getWorkingDayList } from "../../_redux/actions/SecondarySales/FundAction";
import { dateTimeConvertLongString } from "../../../../../domains/CCO/utils/DateHelper";
import { FaSearch } from "react-icons/fa";

const DemandDecisionDayList = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const workingdayList = useSelector((state) => state.fund.workingdayList);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);


  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (item) => {
  
    dispatch(salesDelete(item,selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (item) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
   
    dispatch(EditDateGet(item));
    // history.push(`/secondary/edit/${item.soid}/${item.custName}/${item.orderCode}`);
    // history.push(`/secondary/edit/${item.soid}/${item.sDate}`);
    history.push(`/secondary/edit/${item.soPointID}/${item.sDate}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const showReport =()=>{
    dispatch(GetDemandDecisionDays(startDate));
  }


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-2">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Demand Open Stock Date List</h3>
            </div>
          </div>
    
        </div>

        <hr></hr>

        <div className="form-group row">
        <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Month</label>
              <Form.Control
                type="month"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
        </div>

            <div className="col mt-6 mb-2">
            <Button size="md" onClick={()=>showReport()}><FaSearch /> Show Report</Button>
            </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="shortstickyTable"
                style={{ height: workingdayList?.length > 0 ? 800 + "px" : 0 }}
              >
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  <th scope="col">SL</th>
                  <th scope="col">DATE</th>
                </tr>
              </thead>
              <tbody>
                {workingdayList?.length > 0 &&
                  workingdayList?.map((item, index) => (
                    <tr>
                       <td >{index + 1}</td>
                      <td>{ dateTimeConvertLongString(item.workingDate)}</td>
                      
                      
                     
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default DemandDecisionDayList;
