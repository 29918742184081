import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import NewLoadind from "../../../../master/spinner/NewLoadind";


const DistributorReportBrDataTableWithAllData = () => {


    const dispatch = useDispatch();
    const distributorBrReport = useSelector((state) => state.disReport.distributorBrReport);
    console.log('distributorBrReport :>> ', distributorBrReport);
    const isLoading = useSelector((state) => state.disReport.isLoading);

    let ref = React.createRef();
    const inputField = React.useRef(null);
    const componentRef = useRef();

    const [searchQuery, setSearchQuery] = useState("");


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const filteredReport = distributorBrReport?.filter((item) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        return (
            item.distID.toString().toLowerCase().includes(lowerCaseQuery) ||
            item.disName.toLowerCase().includes(lowerCaseQuery)
        );
    });


    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1px',
    };

    const valueStyle = {
        color: 'yellow',
        fontSize: '13px',
    };


    return (
        <>
            <div className="mt-4">

                <div className="row" id="id" ref={inputField}>
                    {isLoading && <span> <NewLoadind /></span>}
                    <div>
                        {
                            distributorBrReport?.length > 0 &&
                            <div className="search-bar" style={{ marginBottom: '8px' }}>

                                <div className="search-input-container mb-2">
                                    <input
                                        type="text"
                                        placeholder={`${filteredReport?.length} Records Found - Search by Distributor ID or Distributor Name`}
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="search-input"
                                    />
                                    <span className="search-icon">🔍</span>
                                </div>

                            </div>

                        }


                        <div className="table-responsive">
                            <div
                                className="stickyTablecus"
                                style={{ height: filteredReport?.length > 0 ? 500 + "px" : 0 }}
                            >
                                <div className="container-fluid mb-1 containerFixed">
                                    {

                                        <div className="container-fluid mb-2 containerFixed">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                    <div className="paid__widget one">
                                                        <div className="widget__left">
                                                            <span className="paid__widget_New one text-center text-bold target-label-cumalative-bank">Total Bank Receive Balance</span>
                                                            <div className="achiveLayer">
                                                                <span style={containerStyle}>
                                                                    <span style={valueStyle}>৳ {numberFormatDecemal(parseFloat(filteredReport.reduce((sum, item) => sum + item.bankReceive, 0)).toFixed(2))}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6">
                                                    <div className="paid__widget two">
                                                        <div className="widget__left">
                                                            <span className="paid__widget_New two text-center text-bold target-label-cumalative-bank">Net Balance</span>
                                                            <div className="achiveLayer">
                                                                <span style={containerStyle}>
                                                                    <span style={valueStyle}>
                                                                        ৳ {numberFormatDecemal(
                                                                            parseFloat(
                                                                                filteredReport.reduce((sum, item) =>
                                                                                    sum + item.openingBalance +
                                                                                    item.bankReceive +
                                                                                    item.adjustAmount +
                                                                                    item.creditLimit -
                                                                                    item.deliveryAmount -
                                                                                    item.pendingAmount +
                                                                                    item.damageAmount, 0)
                                                                            ).toFixed(2))}
                                                                    </span>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>


                                <table className="distributor-month-balance" id="table-to-xls" ref={componentRef}>
                                    <thead className="banktablehead">
                                        {filteredReport?.length > 0 && (
                                            <tr>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#D0F4D0',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                        width: '70px',
                                                    }}
                                                >
                                                    Distributor ID
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#D0F4D0',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                        width: '200px',
                                                    }}
                                                >
                                                    Distributor NAME
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Opening Balance
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Bank Receive
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Adjust Value
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Credit
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Available Balance
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Primary Demand
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Delivery Value
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Pending Value
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Ledger With Credit Limit
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Replace Value
                                                </th>

                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#D0F4D0',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Available Balance for Demand
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Damage Demand
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Damage Balance
                                                </th>
                                                {/* <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Damage Pending Amount
                                                </th> */}
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#D0F4D0',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Damage Net Balance
                                                </th>
                                                <th
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: '#E2DDD7',
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Net Balance
                                                </th>
                                            </tr>
                                        )}
                                    </thead>

                                    <tbody>
                                        {filteredReport?.length > 0 &&
                                            filteredReport.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ width: '70px', textAlign: 'center', backgroundColor: '#D0F4D0' }}>{item.distID}</td>
                                                    <td style={{ width: '200px', backgroundColor: '#D0F4D0' }}>{item.disName}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.openingBalance)}</td>
                                                    <td className="text-right">{(item.bankReceive)}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.adjustAmount)}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.creditLimit)}</td>
                                                    <td className="text-right" >{numberFormatDecemal(parseFloat(item.openingBalance + item.bankReceive + item.adjustAmount + item.creditLimit).toFixed(2))}</td>
                                                    <td className="text-right">
                                                        {numberFormatDecemal(parseFloat(item.demandAmount + item.superAmount - item.replaceAmount).toFixed(2))}
                                                    </td>
                                                    <td className="text-right">{numberFormatDecemal(item.deliveryAmount)}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.pendingAmount)}</td>
                                                    <td className="text-right" style={{ color: 'black', backgroundColor: '#E2DDD7' }}>{numberFormatDecemal(parseFloat(item.openingBalance + item.bankReceive + item.adjustAmount + item.creditLimit - item.deliveryAmount).toFixed(2))}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.replaceAmount)}</td>
                                                    <td
                                                        className="text-right"
                                                        style={{ color: 'black', backgroundColor: '#D0F4D0' }}
                                                    >
                                                        {numberFormatDecemal(parseFloat(item.openingBalance + item.bankReceive + item.adjustAmount + item.creditLimit - item.deliveryAmount - item.pendingAmount).toFixed(2))}
                                                    </td>
                                                    <td className="text-right">{numberFormatDecemal(item.damageDemandAmount)}</td>
                                                    <td className="text-right">{numberFormatDecemal(item.damageAmount)}</td>
                                                    {/* <td className="text-right">{0}</td> */}
                                                    <td
                                                        className="text-right"
                                                        style={{ color: 'black', backgroundColor: '#D0F4D0' }}
                                                    >
                                                        {item.damageAmount}
                                                    </td>
                                                    <td className="text-right">
                                                        {(parseFloat(
                                                            item.openingBalance +
                                                            item.bankReceive +
                                                            item.adjustAmount +
                                                            item.creditLimit -
                                                            item.deliveryAmount -
                                                            item.pendingAmount +
                                                            item.damageAmount
                                                        ).toFixed(2))}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DistributorReportBrDataTableWithAllData;
