import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesListDetails,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import ReactToPrint from "react-to-print";
import { Dropdown, Card } from "react-bootstrap";
import Pdf from "react-to-pdf";



const SecondarySalesDetails = ({ handleClose, itemID, itemDetailnIfo }) => {
  const ItemDetails = useSelector((state) => state.remoteSalesbread.salesDetails);

  const ref = React.createRef();


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesListDetails(itemDetailnIfo?.soPointID,itemDetailnIfo?.sDate));
  }, []);
  return (
    <>
      {ItemDetails && (
        <div className="container">
          <div className="col-md-12">
            <div className="invoice">
              <div className="export text-right">
                <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    className="btn btn-sm"
                    variant="light text-primary"
                    id="dropdown-basic"
                  >
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Pdf
                      targetRef={ref}
                      filename="Certificate Type.pdf"
                      x={0.5}
                      y={0.5}
                      scale={0.93}
                    >
                      {({ toPdf }) => (
                        <Dropdown.Item onClick={toPdf}>
                          <i class="far fa-file-pdf"></i>
                          <span className="ml-3">Pdf</span>
                        </Dropdown.Item>
                      )}
                    </Pdf>

                    {/* <Dropdown.Item>
                      <i class="far fa-file-excel mt-1"></i>
                      {
                        <ReactHTMLTableToExcel
                          className="excelBtn ml-1"
                          table="table-to-xls"
                          filename="Certificate Type"
                          sheet="tablexls"
                          buttonText="Excel"
                        />
                      }
                    </Dropdown.Item> */}

                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item href="#/action-1" onClick={window.print}>
                          <i class="fas fa-print"></i>
                          <span className="ml-2">Print</span>
                        </Dropdown.Item>
                      )}
                      content={() => ref.current}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div ref={ref} id="printDiv">
                <div className="text-left ml-5 text-body text-bold">
                  {itemDetailnIfo?.custName}
                </div>
                <div>
                  {/* <div className="invoice-to">
                      <address className="m-t-5 m-b-5">
                         <strong className="text-inverse">Company Name</strong>
                         Street Address<br/>
                         City, Zip Code<br/>
                         Phone: (123) 456-7890<br/>
                         Fax: (123) 456-7890<br/>
                      </address>
                    
                   </div> */}
                  <div className="invoice-date">
                    <small>Invoice</small>
                    {/* <div className="date text-inverse m-t-5">August 3,2012</div> */}
                    <div className="invoice-detail">
                      Invoice No:
                      {itemDetailnIfo?.orderCode}
                    </div>
                  </div>
                  <div className="invoice-content item-add-table" >
                    <table className="table table-responsive">
                      <table class="table table-bordered  bg-warning-o-10" style={{width:'100%'}}>
                        <thead>
                          <tr>
                            <th>Sales Id</th>
                            <th>Item Id</th>
                            <th>
                              Item Name
                            </th>
                            <th >Quntity</th>
                            <th >VALUE</th>
                            <th >Total</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {ItemDetails.map((item) => (
                            <tr>
                              <td style={{width:'10%'}}>{item.salesId}</td>
                              <td style={{width:'10%'}}>{item.itemID}</td>
                              <td className="text-left" style={{width:'60%'}}>{item.itemName}</td>
                              <td className="text-left" style={{width:'30%'}}>
                                {item.itemQuentityPic}
                              </td>
                              <td className="text-left" style={{width:'30%'}}>
                                {item.prodTrdpricebypic}
                              </td>
                              <td className="text-left" style={{width:'30%'}}>
                                {item.prodTrdprice}
                              </td>
                              {/* <td className="text-right">{item.totalPrice}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </table>
                    {/* <div className="invoice-price">
                      <div className="invoice-price-right-text">
                        <span mt-5>Quntity</span>{" "}
                        <span>{totalQuantity}</span>
                      </div>
                    </div> */}
                    
                  </div>
                </div>
              </div>

              {/* <div className="invoice-note">
                   * Make all cheques payable to [Your Company Name]
                   * Payment is due within 30 days
                   * If you have any questions concerning this invoice, contact  [Name, Phone Number, Email]
                </div> */}

              {/* <div className="invoice-footer">
                   <p className="text-center m-b-5 f-w-600">
                      THANK YOU FOR YOUR BUSINESS
                   </p>
                   <p className="text-center">
                      <span className="m-r-10"><i className="fa fa-fw fa-lg fa-globe"></i> matiasgallipoli.com</span>
                      <span className="m-r-10"><i className="fa fa-fw fa-lg fa-phone-volume"></i> T:016-18192302</span>
                      <span className="m-r-10"><i className="fa fa-fw fa-lg fa-envelope"></i> rtiemps@gmail.com</span>
                   </p>
                </div> */}
            </div>
          </div>
        </div>
      )}
      <Button
        variant="secondary"
        classNameNameName="float-right"
        onClick={handleClose}
      >
        Close
      </Button>
    </>
  );
};

export default SecondarySalesDetails;
