import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col, Button } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  getDistributorList,
  productQuantity,
  InputHandle,
  cleanRemoteSalesData,
  GetNewCustomerBalanceByID,
  GetDemandApproveAndPendingValueByCustomer,
  GetPrimarySalesTradeOfferForEntry,
  GetCustomerAllTypesOfBalance,
  GetDemandProductLiveStock,
  CheckDemandLiveStock,
  ClearProductListForDemand,
  GetCorporateSalesDeliveryAllZoneEntry,
  corporateSalesSubmitWithLiveStock,
  SalesSubmitAutoApprove
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivisionEntry, getDistributorAddress, getDivisionListByZoneEntry, getTerritoryEntry, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { productSubmitStausRemove } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import RemoteSalesAddPendingAllListShow from "./RemoteSalesAddPendingAllListShow";
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import RemoteSalesAddConfirmedValueDetails from "./RemoteSalesAddConfirmedValueDetails";
import EntryPageAvailableBalanceDetails from "./EntryPageAvailableBalanceDetails";
import NewLoadind from "../../../../master/spinner/NewLoadind";
import { GetDemandDecisionStatus } from "../../_redux/actions/Delivery/DeliveryAction";
import { ClearProductListForDemandBread } from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaArrowRight } from "react-icons/fa";


const CorporateSalesEntryWithLiveStock = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
  const productCost = useSelector((state) => state.remoteSales.productCost);

  const productList = useSelector((state) => state.remoteSales.productList);

  const soWiseProductEntryPage = useSelector((state) => state.remoteSales.soWiseProductEntryPage);
  const skuLoadingEntryPage = useSelector((state) => state.remoteSales.skuLoadingEntryPage);

  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);

  const aLLbalanceData = useSelector((state) => state.remoteSales.aLLbalanceData);
  const statusProduct = useSelector((state) => state.remoteSales.productSubmitStatus);
  const salesApproveData = useSelector((state) => state.remoteSales.salesApproveData);
  const distributorList = useSelector((state) => state.remoteSales.distributorList);


  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [terriory, setTerritory] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [DistributorId, setDistributorId] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [roleType, setRoleType] = useState(0);
  const [selectDistribAddress, setSelectDistribAddress] = useState(null);
  const [distribAddress, setDistribAddress] = useState([]);
  console.log('selectDistribAddress :>> ', selectDistribAddress);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});

  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [decision, setDecision] = useState(false);

  useEffect(() => {
    const fetchDemandDecisionStatus = async () => {
      try {
        const data = await GetDemandDecisionStatus();
        if (data.data?.isDateMatch !== undefined) {
          setDecision(data.data?.isDateMatch);
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    };

    fetchDemandDecisionStatus();
  }, []);


  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    setRoleType(user.role);
    dispatch(cleanRemoteSalesData());
  }, [])



  useEffect(() => {
    if (statusProduct) {
      dispatch(productSubmitStausRemove());
      setTimeout(() => { history.go(0) }, 1200);
    }
  }, [statusProduct]);



  useEffect(() => {
    if (defultTerritory?.value) {
      dispatch(getDistributorList(defultTerritory?.value, salesType));
      inputChangeHandle("intDistributorId", 0);
    }
  }, [defultTerritory]);


  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));

  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });

    let zoneData = await getZoneListEntry();
    Setzone(zoneData.data);
  }

  const changeProductQuantity = (name, value) => {
    if (decision == false) {
      dispatch(productQuantity(name, value));
      dispatch(CheckDemandLiveStock(name.itemID, value));

    } else {
      dispatch(productQuantity(name, value));
    }




  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");

  }


  const handleDemandSubmit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: <span className="confirm-alert-title">Confirm To Submit</span>,
      buttons: [
        {
          label: "Yes",
          onClick: () => onSubmit(e),
          className: "approve-yes-btn",
        },
        {
          label: "No",
          className: "approve-no-btn",
        },
      ],
    });
  };

  const distribAddressDropdownData = () => {
    let options = [];
    if (distribAddress?.length > 0) {
      distribAddress.forEach((item) => {
        let itemData = {
          value: item.distribAddressID,
          label: item.distribAddressName,
        };
        options.push(itemData);
      });
    }
    return options;
  };



  const onSubmit = async (e) => {

    if (selectZone == null || selectZone == 0) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null || selectDivision == 0) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null || selectArea == 0) {
      alert("Please select Region");

      return false;
    } else if (selectTerritory == null || selectTerritory == 0) {
      alert("Please select Territory");
      return false;
    } else if (itemDataInput.intDistributorId == "") {
      alert("Please select Distribution");
      return false;
    }

    if (decision == false) {
      dispatch(corporateSalesSubmitWithLiveStock(NewCustomerBalance, productList, selectZone, selectDivision, selectArea, selectTerritory, startDate, selectDistribAddress));

    } else {
      dispatch(SalesSubmitAutoApprove(NewCustomerBalance, productList, selectZone, selectDivision, selectArea, selectTerritory, startDate, selectDistribAddress));
    }



  };

  const [inputValues, setInputValues] = useState(
    productList.reduce((acc, item) => {
      acc[item.itemID] = item.quantity || "";
      return acc;
    }, {})
  );

  const handleInputChange = (item, value) => {



    const cleanedValue = value.replace(/^0+/, '') || '0';

    setInputValues((prevState) => ({
      ...prevState,
      [item.itemID]: cleanedValue
    }));

    changeProductQuantity(item, cleanedValue);


  };


  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemifo, setItemifo] = useState(null);

  const [itemDetailShow1, setItemDetailShow1] = useState(false);
  const [itemifo1, setItemifo1] = useState(null);

  const [itemDetailShow2, setItemDetailShow2] = useState(false);
  const [itemifo2, setItemifo2] = useState(null);

  const balanceDetails = () => {
    setItemifo(DistributorId);
    setItemDetailShow(true);
  };

  const itemDetail = () => {
    setItemifo1(DistributorId);
    setItemDetailShow1(true);
  };

  const confirmedDetails = () => {
    setItemifo2(DistributorId);
    setItemDetailShow2(true);
  };

  const handleGoToEditPage = () => {
    window.open('/remoteSales/general-sales/list', '_blank');
  };

  const handleGoToApprovedPage = () => {
    window.open('/demand/approved-list', '_blank');
  };

  const handleGoToForApprove = () => {
    window.open('/Primary/RemoteSalesListWithBalance', '_blank');
  };


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };


  const getDistributorAddr = async (id) => {
    let distribData = await getDistributorAddress(id);
    setDistribAddress(distribData.data);
  }




  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-1 ">

          <div className="row">
            <div className="col-md-9">
              <div className="p-2">
                <h2 className="mb-0 pb-0">Corporate Demand Entry</h2>
              </div>
            </div>

            <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            />

          </div>

          <hr></hr>

          <div className="form-group row p-0">

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Deadweight"
                  name="fromDate"
                  value={startDate}
                  className="fromStyle"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);

                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    setValue("intDistributorId", 0);

                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);


                    setdefaultArea(null);
                    setdefultTerritory(null);

                    setValue("areaData", "");
                    setValue("territory", "");
                    setValue("intDistributorId", 0);
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);

                    setdefultTerritory(null);


                    setValue("territory", "");
                    setValue("intDistributorId", 0);
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Territory</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={territoryDropdownData()}
                  value={defultTerritory}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                    setdefultTerritory(option)
                    dispatch(getDistributorList(option?.value, salesType));
                    setValue("intDistributorId", 0);

                  }}
                  defaultValue={defultTerritory}
                />
              </Form.Group>
            </div>



            <div className="col-lg-6">

              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Distributor</label>
                <RHFInput
                  as={<Select options={distributorList} />}
                  rules={{ required: false }}
                  name="intDistributorId"
                  register={register}
                  value=""
                  // value={itemDataInput.intDistributorId}
                  onChange={(option) => {
                    inputChangeHandle("distributorName", option.label);
                    inputChangeHandle("intDistributorId", option.value);
                    // dispatch(getCustomerBalance(option.value));

                    dispatch(GetNewCustomerBalanceByID(option.value));
                    dispatch(GetCustomerAllTypesOfBalance(option.value));
                    dispatch(GetDemandApproveAndPendingValueByCustomer(option.value));

                    dispatch(GetPrimarySalesTradeOfferForEntry());
                    dispatch(GetDemandProductLiveStock());

                    dispatch(ClearProductListForDemand());
                    dispatch(ClearProductListForDemandBread());

                    dispatch(GetCorporateSalesDeliveryAllZoneEntry(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, 1, option.value, salesType, option));




                    getDistributorAddr(option.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Distributor address</label>
                <RHFInput
                  as={<Select options={distribAddressDropdownData()} />}
                  rules={{ required: false }}
                  name="distribAddressID"
                  register={register}
                  value=""
                  // value={itemDataInput.intDistributorId}
                  onChange={(option) => {
                    inputChangeHandle("distribAddressName", option.label);
                    inputChangeHandle("distribAddressID", option.value);
                    setSelectDistribAddress(option.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>

            </div>


            <div className="col-lg-12 text-center d-flex justify-content-center">
              <Button
                className="bg-primary text-white border-0 d-inline mt-4 w-25"
                size="sm"
                onClick={handleGoToEditPage}
              >
                Edit Page <FaArrowRight />
              </Button>
              {/* <Button
                className="bg-info text-white border-0 d-inline mt-4 w-25 ml-3"
                size="sm"
                onClick={handleGoToForApprove}
              >
                For Approve <FaArrowRight />
              </Button> */}
              <Button
                className="bg-success text-white border-0 d-inline mt-4 w-25 ml-3"
                size="sm"
                onClick={handleGoToApprovedPage}
              >
                Approved Report <FaArrowRight />
              </Button>
            </div>


            <div className="col-lg-3 mb-1 mt-3 ">
              {skuLoadingEntryPage === true && <span className="text-center"><NewLoadind /></span>}
            </div>


          </div>

          <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
            <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

              <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }} onClick={() => balanceDetails()}>BALANCE </h5>

                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }} onClick={() => balanceDetails()}>Ledger Balance</span>
                  <span onClick={() => balanceDetails()} style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                    <span className="success-msg-entry-page">(+)</span>
                    {
                      (() => {
                        const openingBalance = isNaN(parseFloat(NewCustomerBalance?.generaL_BALANCE)) ? 0.00 : parseFloat(NewCustomerBalance?.generaL_BALANCE);


                        return numberFormatDecemal(parseFloat(openingBalance).toFixed(2));
                      })()
                    }
                  </span>
                </div>


                {roleType !== 10 && (
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }} onClick={() => balanceDetails()}>Credit Limit</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }} onClick={() => balanceDetails()}><span className="success-msg-entry-page">(+)</span>{isNaN(parseFloat(NewCustomerBalance?.crediT_LIMIT)) ? '0.00' : numberFormatDecemal(parseFloat(NewCustomerBalance?.crediT_LIMIT).toFixed(2))}</span>
                  </div>
                )}


                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }} onClick={() => itemDetail()}>Pending Value </span>
                  <span onClick={() => itemDetail()} style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(-)</span>{
                    Array.isArray(soWiseProductEntryPage) && soWiseProductEntryPage.length > 0
                      ? numberFormatDecemal(parseFloat(soWiseProductEntryPage.reduce((sum, x) => sum + (x.pendingValue || 0), 0)).toFixed(2))
                      : '0.00'
                  }</span>

                </div>

                <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px", color: "#3fab68" }} onClick={() => balanceDetails()}>Available Balance For Demand</span>
                  <span
                    onClick={() => balanceDetails()}
                    style={{
                      float: "right",
                      fontSize: "13px",
                      color: (() => {

                        const openingBalance = isNaN(parseFloat(NewCustomerBalance?.generaL_BALANCE)) ? 0.00 : parseFloat(NewCustomerBalance?.generaL_BALANCE);

                        const creditLimit = roleType !== 10 && !isNaN(parseFloat(NewCustomerBalance?.crediT_LIMIT))
                          ? parseFloat(NewCustomerBalance?.crediT_LIMIT)
                          : 0;

                        const pendingValueSum = Array.isArray(soWiseProductEntryPage) && soWiseProductEntryPage.length > 0
                          ? soWiseProductEntryPage.reduce((sum, x) => sum + (x.pendingValue || 0), 0)
                          : 0;

                        const productCostValue = isNaN(parseFloat(productCost)) ? 0 : parseFloat(productCost);

                        const notConfirmedPending = isNaN(parseFloat(NewCustomerBalance?.pendinG_VALUE_NOT_CONFIRMED)) ? 0 : (parseFloat(NewCustomerBalance?.pendinG_VALUE_NOT_CONFIRMED).toFixed(2))


                        const totalSum = openingBalance + creditLimit - pendingValueSum;


                        return totalSum < 0 ? 'red' : '#3fab68';
                      })(),
                      fontWeight: '500',
                    }}
                  >
                    {(() => {
                      const openingBalance = isNaN(parseFloat(NewCustomerBalance?.generaL_BALANCE)) ? 0.00 : parseFloat(NewCustomerBalance?.generaL_BALANCE);

                      const creditLimit = roleType !== 10 && !isNaN(parseFloat(NewCustomerBalance?.crediT_LIMIT))
                        ? parseFloat(NewCustomerBalance?.crediT_LIMIT)
                        : 0;

                      const pendingValueSum = Array.isArray(soWiseProductEntryPage) && soWiseProductEntryPage.length > 0
                        ? soWiseProductEntryPage.reduce((sum, x) => sum + (x.pendingValue || 0), 0)
                        : 0;

                      const productCostValue = isNaN(parseFloat(productCost)) ? 0 : parseFloat(productCost);
                      const notConfirmedPending = isNaN(parseFloat(NewCustomerBalance?.pendinG_VALUE_NOT_CONFIRMED)) ? 0 : (parseFloat(NewCustomerBalance?.pendinG_VALUE_NOT_CONFIRMED).toFixed(2))

                      const totalSum = openingBalance + creditLimit - pendingValueSum;

                      return numberFormatDecemal(totalSum.toFixed(2));
                    })()}
                  </span>



                </div>

                <span className="float-right warning-msg-entry-page"><strong>( Without Damage Balance )</strong></span>
              </div>


              <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                <div style={{ position: 'relative', textAlign: "center" }}>
                  <h5 style={{ fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }} onClick={() => confirmedDetails()}>
                    DEMAND
                    <span style={{

                      fontSize: '14px',
                      color: '#FFA500',

                      fontWeight: 'bold'
                    }}>
                      ({new Date().toLocaleString('default', { month: 'long' })} Month)
                    </span>
                  </h5>

                </div>


                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Confirmed Value </span>
                  <span onClick={() => confirmedDetails()} style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(salesApproveData)) ? '0.00' : numberFormatDecemal(parseFloat(salesApproveData).toFixed(2))}</span>
                </div>

                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Quantity</span>
                  <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(countProduct)) ? '0.00' : numberFormatDecemal(parseFloat(countProduct).toFixed(2))}</span>
                </div>
                <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Product Value</span>
                  <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}</span>
                </div>


                <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                  <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Demand Value</span>
                  <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                    {isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}
                  </span>
                </div>
              </div>

            </div>
          </div>


        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">

          {productList.length > 0 ? (

            <div className="react-bootstrap-table table-responsive mt-7">

              <table className="table table table-head-custom table-vertical-center ">
                <thead>
                  <th className="bg-secondary">SL</th>
                  <th className="bg-secondary">Item ID</th>
                  <th className="bg-secondary">Item Name</th>
                  <th className="bg-secondary">Available Stock</th>
                  <th className="bg-secondary">P.Delivery Qty</th>
                  <th className="bg-secondary">Trade Offer</th>
                  <th className="bg-secondary">Quantity</th>
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{numberFormatDecemal(item.NetStockQty)}</td>
                      <td>{item.pendingProductQty < 0 ? 0 : item.pendingProductQty}</td>
                      <td>
                        {item.tradeQty === 0
                          ? "No Offer Available"
                          : <>
                            <strong>{item.tradeQty}</strong> CTN, <strong>{item.tradeoffer} CTN FREE</strong>
                          </>}
                      </td>

                      {/* Section for decision == false */}
                      {decision === false && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autoComplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={inputValues[item.itemID] || ""}
                              onChange={(e) => handleInputChange(item, e.target.value)}
                            />
                          </Form.Group>

                          {/* Stock availability message */}
                          {item.LiveStockQty < 0 && (
                            <small className="validation-symbol">
                              Sorry!! Stock is not available
                            </small>
                          )}

                          {/* Offer message */}
                          {inputValues[item.itemID] && item.tradeQty !== 0 && item.itemQuentity < item.tradeQty && (
                            <div className="offer-details">
                              <small className="validation-symbol">
                                Add {item.tradeQty - item.itemQuentity} more for offer
                              </small>
                            </div>
                          )}
                        </td>
                      )}

                      {/* Section for decision == true */}
                      {decision === true && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autoComplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={inputValues[item.itemID] || ""}
                              onChange={(e) => handleInputChange(item, e.target.value)}
                            />
                          </Form.Group>

                          {/* Offer message */}
                          {inputValues[item.itemID] && item.tradeQty !== 0 && item.itemQuentity < item.tradeQty && (
                            <div className="offer-details">
                              <small className="validation-symbol">
                                Add {item.tradeQty - item.itemQuentity} more for offer
                              </small>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>





              </table>
              <div className="col-lg-12 mt-2">

                {
                  statusProduct === false &&
                  <button

                    onClick={(e) => handleDemandSubmit(e)}
                    className="btn btn-success btn-block"
                  >
                    Submit
                  </button>
                }

              </div>
            </div>
          ) : null}
        </div>
      </form>


      <SimpleModal
        size="lg"
        show={itemDetailShow}
        handleClose={() => setItemDetailShow(false)}
        handleShow={() => setItemDetailShow(true)}
      >
        <EntryPageAvailableBalanceDetails
          handleClose={() => setItemDetailShow(false)}
          BalanceData={aLLbalanceData}
          roleType={roleType}

        />
      </SimpleModal>


      <SimpleModal
        size="xl"
        show={itemDetailShow1}
        handleClose={() => setItemDetailShow1(false)}
        handleShow={() => setItemDetailShow1(true)}
      >
        <RemoteSalesAddPendingAllListShow
          handleClose={() => setItemDetailShow1(false)}
          itemDetailnIfo1={itemifo1}

        />
      </SimpleModal>

      <SimpleModal
        size="xl"
        show={itemDetailShow2}
        handleClose={() => setItemDetailShow2(false)}
        handleShow={() => setItemDetailShow2(true)}
      >
        <RemoteSalesAddConfirmedValueDetails
          handleClose={() => setItemDetailShow2(false)}
          itemDetailnIfo1={itemifo2}

        />
      </SimpleModal>



    </>
  );
};

export default CorporateSalesEntryWithLiveStock;
