import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetGeneralSalesProductStock } from '../../../information/_redux/actions/Report/ReportAction';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DotLoading from '../../../../master/spinner/DotLoading';
import { numberFormatDecemal } from '../../../../../domains/CCO/utils/DateHelper';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FaFileExcel } from 'react-icons/fa';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const GeneralSalesProductStock = () => {
    const dispatch = useDispatch();
    const generalSalesStockLoading = useSelector((state) => state.target.generalSalesStockLoading);
    const generalSalesStock = useSelector((state) => state.target.generalSalesStock);
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        if (showDiv1) {
            dispatch(GetGeneralSalesProductStock(1));
        } else if (showDiv2) {
            dispatch(GetGeneralSalesProductStock(2)); 
        }
    }, [showDiv1, showDiv2, dispatch]);


    useEffect(() => {
        if (generalSalesStock && generalSalesStock.length) {
            const sortedData = [...generalSalesStock].sort((a, b) => b.availableStock - a.availableStock);
            setProductData(sortedData);
        }
    }, [generalSalesStock]);


    const ExcelButton = () => (
        <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn ml-1"
            table="table-to-xls"
            filename={`Product Stock (General Sales) - Report Date : (${new Date().toLocaleDateString()})`}
            sheet="tablexls"
            buttonText={
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "blue",
                        color: "white",
                        padding: "6px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        outline: "none",
                        border: "none",
                        height: "23px",
                        textAlign: "center"
                    }}
                >
                    <FaFileExcel />

                </span>
            }
        />
    );

    const checkboxStyle = {
        margin: '5px',
        cursor: 'pointer',
    };

    const labelStyle = {
        marginRight: '5px',
        fontWeight: 'bold',
        color: '#333',
    };


    const [showDiv1, setShowDiv1] = useState(true);
    const [showDiv2, setShowDiv2] = useState(false);
    console.log('showDiv1', showDiv1)

    const handleClick = (div) => {
        if (div === 'div1') {
            setShowDiv1(true);
            setShowDiv2(false);
            dispatch(GetGeneralSalesProductStock(1));
        } else {
            setShowDiv2(true);
            setShowDiv1(false);
            dispatch(GetGeneralSalesProductStock(2));
        }
    };

    return (
        <>
            <div className='col-lg-12'>
                <h4>Product Stock Report ( General Sales ) <ExcelButton /></h4>

                <div>
                    <label style={labelStyle}>
                        <input
                            type="checkbox"
                            checked={showDiv1}
                            onChange={() => handleClick('div1')}
                            style={checkboxStyle}
                        /> UNHOLD
                    </label>
                    <label style={labelStyle}>
                        <input
                            type="checkbox"
                            checked={showDiv2}
                            onChange={() => handleClick('div2')}
                            style={checkboxStyle}
                        /> HOLD
                    </label>
                </div>

            </div>


            {generalSalesStockLoading && <DotLoading />}



            <div className='container-fluid fullcardbg'>
                <div className="row">
                    <div className="table-responsive-category" style={{ overflowY: 'auto', maxHeight: '500px' }}>
                        <table className="table table-striped custom-table" id="table-to-xls">
                            <thead>
                                <tr>
                                    <th className="text-center"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1 // Ensures header stays above content while scrolling
                                        }}>
                                        SL
                                    </th>
                                    <th className="product-name"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Product Id
                                    </th>
                                    <th className="product-name"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Product Name
                                    </th>
                                    <th className="product-name"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Sales Price
                                    </th>
                                    <th className="product-name"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        TP Price
                                    </th>
                                    <th className="product-name"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Adj IP Price
                                    </th>
                                    <th className="demand-can-be-taken text-center"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Demand Can Be Taken
                                    </th>
                                    <th className="demand-qty text-center"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Demand Quantity
                                    </th>
                                    <th className="available-stock text-center"
                                        style={{
                                            textTransform: 'uppercase',
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1
                                        }}>
                                        Available Stock
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {productData.map((item, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td className="product-name">{item.productId}</td>
                                        <td className="text-left">{item.productName}</td>
                                        <td className="product-name">{item.salesPrice}</td>
                                        <td className="product-name">{item.tpPrice}</td>
                                        <td className="product-name">{item.adj_IP_Price}</td>
                                        <td className="demand-can-be-taken text-center">{numberFormatDecemal(parseInt(item.demandCanBeTaken))}</td>
                                        <td className="demand-qty text-center">{numberFormatDecemal(parseInt(item.demandQty))}</td>
                                        <td className="available-stock text-center">{numberFormatDecemal(parseInt(item.availableStock))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </>
    );
};

export default GeneralSalesProductStock;
