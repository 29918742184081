import { indexOf } from "lodash";
import * as Types from "../../types/Types";

const initialState = {
  submitLoading: false,
  productSubmitStatus: false,
  productList: [],
  isLoading: false,
  zoneSubmitStatus: false,
  productUpdateStatus: false,
  productSubmitStatus: false,
  restBalance: 0,
  salesDetails: [],
  productQuantity: 0,
  totalSalesDetailsAmount: null,
  totalDetQuantity: null,
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  salesUpdateData: [],
  submitData: {
    intCustomer: 0,
    monCustBalance: 0,
    coaid: 0,
    intsalesofficeid: 0,
    DistribPointID: 0,
    DelPointID: 0,
  },
  balanceLoader: false,
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
  productCost: 0,
  productList: [],
  filterProduct: [],
  isLoggedIn: false,
  selectedProduct: [],
  availableBalance: 0,
  creditLimit: 0,
  demandlist: [],
  giftlist: [],
  productDelivery: [],
  customerDeliveryProd: [],
  isPProductLoading: false,
  isDeDetails: false,
  salesEditInfo: null,
  submitLoading: false,
  damageApprovedList: [],
  salesApprovedDetails: [],
  isProductLoading: false,
  damageLoadingEntryPage: false
};
const DamageDemandReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case Types.DAMAGE_DEMAND_SUBMIT:
      return {
        ...state,
        submitLoading: false,
        productSubmitStatus: action.payload.status,

      };
    case Types.GET_DAMAGED_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload.data == null ? [] : action.payload.data,
      };

    case Types.GET_DAMAGED_PRODUCT_LIST_WITH_LIVE_STOCK:
      
      const productList = action.payload.data == null ? [] : action.payload.data;
      const liveStock = Array.isArray(state.demandliveStock) ? [...state.demandliveStock] : [];

      const mergedLiveStockProduct = productList.map(product => {
        const matchingProduct = liveStock.find(entry => entry.producrId === product.itemID);
        const liveProductStock = matchingProduct ? matchingProduct.liveProductStock : 0;
        const NetStockQty = matchingProduct ? matchingProduct.netProductStock : 0;
        return {
          ...product,
          LiveStockQty: liveProductStock,
          NetStockQty: NetStockQty
        };
      });

      const combinedList = [
        ...mergedLiveStockProduct
      ];


      const uniqueProducts = combinedList.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.itemID === value.itemID
        ))
      );

      const finalProductList = uniqueProducts.map(product => {
        const liveStockProduct = mergedLiveStockProduct.find(p => p.itemID === product.itemID) || {};
        return {
          ...product,
          LiveStockQty: liveStockProduct.LiveStockQty || 0,
          NetStockQty: liveStockProduct.NetStockQty || 0
        };
      });

      return {
        ...state,
        productList: finalProductList,
        damageLoadingEntryPage: action.payload.isLoading,
      };


    case Types.CHANGE_DAMAGE_PRODUCT_QUANTITY:
      let quantityvalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const product = state.productList.slice();
      const avaiblebalance = state.availableBalance;
      let sumItem = 0;
      let restBalance = 0;
      let rest;
      let productQuantity = 0;
      for (let i = 0; i < product.length; i++) {
        if (product[i].itemID === action.payload.item.itemID) {
          product[i].itemQuentity = quantityvalue;
          product[i].totalPrice =
            product[i].itemQuentity * product[i].itemPrice;
        }
        sumItem += product[i].itemQuentity * product[i].itemPrice;
        restBalance = avaiblebalance - sumItem;
        productQuantity += product[i].itemQuentity;
      }
      return {
        ...state,
        productList: product,
        productCost: sumItem,
        productQuantity: productQuantity,
        restBalance,
        // salesUpdateData:product
        // itemDataInput:productClone
      };

    case Types.GET_CUSTOMER_BALANCE:
      let submitData = { ...state.submitData };
      submitData.intCustomer = action.payload.data.cusid;
      submitData.monCustBalance = action.payload.data.availableBalance;
      submitData.coaid = action.payload.data.intcoaid;
      submitData.intsalesofficeid = action.payload.data.intsalesoffid;
      submitData.disPointID = action.payload.data.disPointID;
      submitData.delPointID = action.payload.data.delPointID;
      submitData.distribCat = action.payload.data.distribCat;

      return {
        ...state,
        availableBalance: action.payload.data.availableBalance,
        creditLimit: action.payload.data.moncreditlimit,
        balanceLoader: action.payload.isLoading,
        restBalance: action.payload.data.availableBalance,
        submitData,
      };


    case Types.GET_DAMAGE_APPROVED_SALES_REPORT:
      return {
        ...state,
        damageApprovedList: action.payload.data == null ? [] : action.payload.data,
        approvedLoading: action.payload.isLoading,
      };
    case Types.GET_DAMAGE_PRODUCT_DEMAND_LIST:
      return {
        ...state,
        demandlist: action.payload.data,
      };


    case Types.GET_DAMAGE_DEMAND_LIVE_PRODUCT_STOCK:

      return {
        ...state,
        demandliveStock: action.payload.data,

      };

      case Types.CHECK_DEMAND_LIVE_STOCK_DAMAGE:
      console.log('action.payload.data[0] :>> ', action.payload.data[0]);
      const LiveData = action.payload.data[0];
      console.log('LiveData :>> ', LiveData);
      const cloneProduct = state.productList.slice();
      console.log('cloneProduct', cloneProduct)

      for (let i = 0; i < cloneProduct.length; i++) {
        if (cloneProduct[i].itemID == LiveData?.productId) {
          cloneProduct[i].LiveStockQty = LiveData?.liveProductStock
          cloneProduct[i].NetStockQty = LiveData?.netProductStock
        }
      }
      return {
        ...state,
        productList: cloneProduct,
      };


      case Types.SALES_PRODUCT_SUBMIT_DAMAGE:
        return {
          ...state,
          submitLoading: false,
          productSubmitStatus: action.payload.status,
          availableBalance: 0,
          creditLimit: 0,
          productCost: 0,
          countProduct: 0,
          productQuantity: 0,
          submitsecondaryStatus: action.payload.status,
        };


      case Types.SALES_PRODUCT_SUBMIT_FAILED_DAMAGE:
        const LiveDataAll = action.payload?.data;
        const cloneProductAll = [...state.productList];
        console.log('cloneProductAll ', cloneProductAll);
        console.log('LiveDataAll :>> ', LiveDataAll);
  
        cloneProductAll.forEach(product => {
          const matchedProduct = LiveDataAll?.find(liveProduct => liveProduct.producrId === product.itemID);
          if (matchedProduct) {
            product.LiveStockQty = matchedProduct.liveProductStock;
            product.NetStockQty = matchedProduct.netProductStock;
          }
        });
  
        return {
          ...state,
          productList: cloneProductAll,
          submitLoading: false,
          productSubmitStatus: action.payload.status,
          availableBalance: 0,
          creditLimit: 0,
          productCost: 0,
          countProduct: 0,
          productQuantity: 0,
          submitsecondaryStatus: action.payload.status,
        };


        case Types.CLEAR_REMOTE_SALES_DATA:
      return {
        ...state,
        availableBalance: 0,
        restBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        salesApprovedList: [],
        demandlist: [],
        productList: []
        // productSubmitStatus:false

      };
  

      

    default:
      break;
  }
  return newState;
};

export default DamageDemandReducer;

