import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import authMenuPermissionReducer from "../app/modules/Auth/_redux/menu-permission/authMenuPermissionReducer";
import RolePermissionManagementReducer from "../modules/role-permission-management/information/_redux/reducers/RolePermissionManagementReducer";

import UserRoleReducer from "../modules/role-permission-management/information/_redux/reducers/UserRoleReducer";
import ZoneReducer from "../modules/Sales/information/_redux/reducers/Zone/ZoneReducer";
import RemoteSalesReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/RemoteSalesReducer";
import DistributorReportReducer from "../modules/Sales/information/_redux/reducers/DistributorReport/DistributorReportReducer";
import SecondarySalesReducers from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondarySalesReducers";
import SecodnaryReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecodnaryReducer";
import BreadBurnReportReducer from "../modules/Sales/information/_redux/reducers/BreadBurnRemoteSales/Reports/BreadBurnReportReducer";
import DeliveryReducer from "../modules/Sales/information/_redux/reducers/Delivery/DeliveryReducer";
import TargetReducer from "../modules/Sales/information/_redux/reducers/Target/TargetReducer";
import StockReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/StockReducer";
import SecondarySalesFebReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondarySalesFebReducer";
import SecondaryRollbackReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondaryRollbackReducer";
import SubDepoReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/SubDepoReducer";
import FundReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/FundReducer";
import DashboardReducer from "../modules/Sales/information/_redux/reducers/Dashboard/DashboardReducer";
import PrimaryReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/PrimaryReducer";
import PromotionalReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/PromotionalReducer";
import DamageDemandReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/DamageDemandReducer";
import DashboardCartReducer from "../modules/Sales/information/components/Dashboard/DashboardCartReducer";
import DamageReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/DamageReducer";
import BreadBurnDashboardReducer from "../modules/Sales/information/_redux/reducers/Dashboard/BreadBurnDashboardReducer";
import SoDashboardReducer from "../modules/Sales/information/components/Dashboard/SoDashboardReducer";
import ItemReducer from "../modules/item/information/_redux/reducers/ItemReducer";
import BreadRemoteSalesReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/BreadRemoteSalesReducer";
export const rootReducer = combineReducers({
  auth: auth.reducer,

  /** Menu Reducers */
  menu: authMenuPermissionReducer,
  // demand: DemandReducer,
  roleReducer: RolePermissionManagementReducer,
  userRole: UserRoleReducer,

  // Sales reducers start here
  zone: ZoneReducer,
  remoteSales: RemoteSalesReducer,
  remoteSalesbread: BreadRemoteSalesReducer,
  disReport: DistributorReportReducer,
  secondarySales: SecondarySalesReducers,
  secondary: SecodnaryReducer,
  bradBurn: BreadBurnReportReducer,
  delivery: DeliveryReducer,
  target: TargetReducer,
  stock:StockReducer,
  secondaryFeb:SecondarySalesFebReducer,
  rollback:SecondaryRollbackReducer,
  subDistri:SubDepoReducer,
  fund:FundReducer,
  primary: PrimaryReducer,
  promotional: PromotionalReducer,
  itemList: ItemReducer,

  // Dashboard reducers start here
  dashboard:DashboardReducer,

  // Damaged reducers start here
  damagedemand: DamageDemandReducer,

  //DashboradCart reducer
  dashboardcart :DashboardCartReducer,
  //damage
  damage: DamageReducer,
  //bradeburn
  breadburn: BreadBurnDashboardReducer,
  //soDashboard
  sodash: SoDashboardReducer


});

export function* rootSaga() {
  yield all([auth.saga()]);
}
