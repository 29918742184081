import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet
} from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";
import { InputHandle } from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  GetDivisionForDistributorSalesEntry,
  getDivisionListByZone,
  GetRegionForDistributorSalesEntry,
  getTerritory,
  GetTerritoryForDistributorSalesEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { getsubSalesList, handleSubSalesApperoved, SubsalesDelete } from "../../_redux/actions/RemoteSales/SuperDepoAction";
import SubSalesListDetails from "./SubSalesListDetails";
import { GetDisZDAreaTerriDistbpoints } from "../Dashboard/DashboardCartAction";
import { GetZoneForDistributorSalesEntry } from "../../_redux/actions/Zone/ZoneAction";

const DisSuperDisDemandList = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const demandlist = useSelector((state) => state.subDistri.demandlist);
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
  }, []);

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetDisZDAreaTerriDistbpoints()
      .then((res) => {
        console.log('resNew :>> ', res.data);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, employeeType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

        setSalesType(employeeType);
        setDistribType(employeeType);

      });

    let zoneData = await GetZoneForDistributorSalesEntry();
    Setzone(zoneData.data);
  }



  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(SubsalesDelete(id, selectArea, salesType));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };


  const divisionList = async (id) => {
    let divisionData = await GetDivisionForDistributorSalesEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await GetRegionForDistributorSalesEntry(id, selectGeoType);
    setArea(areaData.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await GetTerritoryForDistributorSalesEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    console.log('item', item);
    dispatch(EditDateGet(item));
    history.push(`/subSales/edit/${item.orderId}/${item.custName}/${item.orderCode}/${item.salesGroupId}`);
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const handleApprovedList = (item) => {
    confirmAlert({
      title: "Confirm To Approved",
      message: `Are you sure to Approved..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(handleSubSalesApperoved(item, selectArea, salesType)),
        },
        {
          label: "No",
        },
      ],
    });

  }


  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };





  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        {/* <div className="container"> */}
        {/* <RemoteSalesFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Super Distributor Demand List</h3>
            </div>
          </div>

          {/* <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          /> */}
        </div>

        <hr></hr>

        <div className="form-group row">
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);

                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);

                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                  dispatch(getsubSalesList(selectArea, salesType, option.value));

                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  {/* <th scope="col">Department</th> */}
                  {/* <th scope="col">ORDER ID</th> */}
                  <th scope="col">ORDER CODE</th>
                  <th scope="col">CUSTOMER ID</th>
                  <th scope="col">CUSTOMER NAME</th>
                  <th scope="col">QUANTITY</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.orderCode}</td>
                      <td>{item.custId}</td>
                      <td>{item.custName}</td>
                      <td>{item.totalQuenty}</td>
                      <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        {/* <Link to={`/remoteSales/edit/${item.orderId}`}>
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link> */}

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                        <a
                          href
                          onClick={(id) => handleApprovedList(item.orderId)}
                          title="Approved"
                        >
                          <i className="fa fa-check editIcon item-list-icon ml-4"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SubSalesListDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default DisSuperDisDemandList;
