import { indexOf } from "lodash";
import * as Types from "../../types/Types";

const initialState = {
  isLoading: false,
  zoneSubmitStatus: false,
  productUpdateStatus: false,
  productSubmitStatus: false,
  submitsecondaryStatus: false,
  restBalance: 0,
  distributionSalesGroupId: 0,
  salesDetails: [],
  productQuantity: 0,
  totalSalesDetailsAmount: null,
  totalDetQuantity: null,
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  salesUpdateData: [],
  submitData: {
    intCustomer: 0,
    monCustBalance: 0,
    coaid: 0,
    intsalesofficeid: 0,
    DistribPointID: 0,
    DelPointID: 0,
  },
  balanceLoader: false,
  multipleItemAdd: [],
  itemList: [],
  itemListPaginated: null,
  itemSUbmit: [],
  productCost: 0,
  productList: [],
  filterProduct: [],
  isLoggedIn: false,
  selectedProduct: [],
  availableBalance: 0,
  creditLimit: 0,
  demandlist: [],
  demandlistWithBalance: [],
  demandlistWithBalanceLoading: false,
  giftlist: [],
  productDelivery: [],
  customerDeliveryProd: [],
  isPProductLoading: false,
  isDeDetails: false,
  salesEditInfo: null,
  submitLoading: false,
  salesApprovedList: [],
  salesApprovedDetails: [],
  isProductLoading: false,
  distibListforsms: [],
  smsStatus: false,
  damageCustomerbyUnit: [],
  stockStatusUpdateList: [],
  stockStatusUpdateLoading: false,
  verticlePridistributorReport: [],
  verticlePridistributorReportLoading: false,
  soWiseProductEntryPage: [],
  tradeofferEntryPage: [],
  demandliveStock: [],
  skuLoadingEntryPage: false,
  salesApproveData: 0,
  salesApproveDataList: [],
  salesApproveDataLoading: false,
  smsreportLoading: false,
  allPendingList: [],
  allPendingListLoading: false,
  aLLbalanceDataLoading: false,
  aLLbalanceData: {},
  balanceData: [],
  NegativeStock: []


};
const RemoteSalesReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_PRODUCT_INPUT:
      const itemDataInput = { ...state.itemDataInput };
      itemDataInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInput,
      };
    case Types.GET_DISTRIBUTOR_LIST:
      console.log('action.payload.data', action.payload.data);
      return {
        ...state,
        distributorList: getDistributor(action.payload.data),
        SalesGroupdistributorList: getSalesGroupDistributor(action.payload.data),
      };
    case Types.GET_STOCK_STATUS_UPDATE:
      return {
        ...state,
        stockStatusUpdateList: action.payload.data,
        stockStatusUpdateLoading: action.payload.status
      };
    case Types.GET_DAMAGE_CUSTOMER_BY_UNIT:
      console.log('action.payload.data', action.payload.data);
      return {
        ...state,
        damageCustomerbyUnit: getDamageCustomerbyUnit(action.payload.data),
      };
    case Types.ZONE_SAVE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        zoneSubmitStatus: true,
      };
    case Types.ZONE_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        zoneSubmitStatus: false,
      };
    case Types.GET_SEARCH_DATA_INPUT:
      const serchInput = { ...state.itemDataInput };
      serchInput.itemName = action.payload.value;

      let searchFilterData = [];
      let searchText = action.payload.value;
      if (searchText.length > 0) {
        searchFilterData = state.productList.filter((item) => {
          const filterProduct = item.zoneName + "" + item.zoneId;
          const itemData = filterProduct.toUpperCase();
          const textData = searchText.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      }
      return {
        ...state,
        filterProduct: searchFilterData,
        itemDataInput: serchInput,
      };

    case Types.GET_DELIVERY_SKU_REPORT_ENTRY_PAGE:
      console.log('123deliveryaction.payload.data :>>', action.payload.data?.allReport);
      return {
        ...state,
        soWiseProductEntryPage: action.payload.data?.allReport,
        skuLoadingEntryPage: action.payload.isProductLoading,

      };

    case Types.GET_SALES_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload.data == null ? [] : action.payload.data
      };

    case Types.GET_SALES_PRODUCT_LIST_ENTRY:
      console.log('product - action.payload.data :>> ', action.payload.data);
      const productList = action.payload.data == null ? [] : action.payload.data;
      const soNewData = Array.isArray(state.soWiseProductEntryPage) ? [...state.soWiseProductEntryPage] : [];
      const tradeOffer = Array.isArray(state.tradeofferEntryPage) ? [...state.tradeofferEntryPage] : [];
      const liveStock = Array.isArray(state.demandliveStock) ? [...state.demandliveStock] : [];

      console.log('soNewData', state.soWiseProductEntryPage);
      console.log('tradeOffer', tradeOffer);
      console.log('liveStock', liveStock)

      const mergedProductList = productList.map(product => {
        const matchingProduct = soNewData.find(entry => entry.productID === product.itemID);
        const pendingProductQty = matchingProduct ? matchingProduct.pendingProductQty : 0;
        const pendingProductValue = matchingProduct ? matchingProduct.pendingValue : 0;
        return {
          ...product,
          pendingProductQty: pendingProductQty,
          pendingProductValue: pendingProductValue
        };
      });

      const mergedTradeProductList = productList.map(product => {
        const matchingProduct = tradeOffer.find(entry => entry.tProductParentId === product.itemID);
        const tradeProductQty = matchingProduct ? matchingProduct.tradeCtn : 0;
        const tradeoffer = matchingProduct ? matchingProduct.tradeOff : 0;
        const offerProduct = matchingProduct ? matchingProduct.tProductId : 0;
        return {
          ...product,
          tradeQty: tradeProductQty,
          tradeoffer: tradeoffer,
          offerProduct: offerProduct
        };
      });

      console.log('mergedTradeProductList :>> ', mergedTradeProductList);

      const mergedLiveStockProduct = productList.map(product => {
        const matchingProduct = liveStock.find(entry => entry.producrId === product.itemID);
        const liveProductStock = matchingProduct ? matchingProduct.liveProductStock : 0;
        const NetStockQty = matchingProduct ? matchingProduct.netProductStock : 0;
        return {
          ...product,
          LiveStockQty: liveProductStock,
          NetStockQty: NetStockQty
        };
      });

      console.log('mergedLiveStockProduct', mergedLiveStockProduct)

      const combinedList = [
        ...mergedProductList,
        ...mergedTradeProductList,
        ...mergedLiveStockProduct
      ];


      const uniqueProducts = combinedList.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.itemID === value.itemID
        ))
      );


      const finalProductList = uniqueProducts.map(product => {
        const tradeProduct = mergedTradeProductList.find(p => p.itemID === product.itemID) || {};
        const liveStockProduct = mergedLiveStockProduct.find(p => p.itemID === product.itemID) || {};
        return {
          ...product,
          tradeQty: tradeProduct.tradeQty || 0,
          tradeoffer: tradeProduct.tradeoffer || 0,
          offerProduct: tradeProduct.offerProduct || 0,
          LiveStockQty: liveStockProduct.LiveStockQty || 0,
          NetStockQty: liveStockProduct.NetStockQty || 0
        };
      });

      console.log('finalProductList :>> ', finalProductList);

      return {
        ...state,
        productList: finalProductList
      };


    case Types.GET_CORPORATE_PRODUCT_LIST_ENTRY:
      console.log('product - action.payload.data :>> ', action.payload.data);
      
      const productListCorporate = action.payload.data == null ? [] : action.payload.data;
      const soNewDataCorportae = Array.isArray(state.soWiseProductEntryPage) ? [...state.soWiseProductEntryPage] : [];
      const tradeOfferCorporate = Array.isArray(state.tradeofferEntryPage) ? [...state.tradeofferEntryPage] : [];
      const liveStockCorporate = Array.isArray(state.demandliveStock) ? [...state.demandliveStock] : [];

      console.log('soNewData', state.soWiseProductEntryPage);
      console.log('tradeOffer', tradeOfferCorporate);
      console.log('liveStock', liveStockCorporate)

      const mergedProductListCorporate = productListCorporate.map(product => {
        const matchingProduct = soNewDataCorportae.find(entry => entry.productID === product.itemID);
        const pendingProductQty = matchingProduct ? matchingProduct.pendingProductQty : 0;
        const pendingProductValue = matchingProduct ? matchingProduct.pendingValue : 0;
        return {
          ...product,
          pendingProductQty: pendingProductQty,
          pendingProductValue: pendingProductValue
        };
      });

      const mergedTradeProductListCorporate = productListCorporate.map(product => {
        const matchingProduct = tradeOfferCorporate.find(entry => entry.tProductParentId === product.itemID);
        const tradeProductQty = matchingProduct ? matchingProduct.tradeCtn : 0;
        const tradeoffer = matchingProduct ? matchingProduct.tradeOff : 0;
        const offerProduct = matchingProduct ? matchingProduct.tProductId : 0;
        return {
          ...product,
          tradeQty: tradeProductQty,
          tradeoffer: tradeoffer,
          offerProduct: offerProduct
        };
      });

      console.log('mergedTradeProductList :>> ', mergedTradeProductListCorporate);

      const mergedLiveStockProductCorporate = productListCorporate.map(product => {
        const matchingProduct = liveStockCorporate.find(entry => entry.producrId === product.itemID);
        const liveProductStock = matchingProduct ? matchingProduct.liveProductStock : 0;
        const NetStockQty = matchingProduct ? matchingProduct.netProductStock : 0;
        return {
          ...product,
          LiveStockQty: liveProductStock,
          NetStockQty: NetStockQty
        };
      });

      console.log('mergedLiveStockProduct', mergedLiveStockProductCorporate)

      const combinedListCorporate = [
        ...mergedProductListCorporate,
        ...mergedTradeProductListCorporate,
        ...mergedLiveStockProductCorporate
      ];


      const uniqueProductsCorporate = combinedListCorporate.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.itemID === value.itemID
        ))
      );


      const finalProductListCorporate = uniqueProductsCorporate.map(product => {
        const tradeProduct = mergedTradeProductListCorporate.find(p => p.itemID === product.itemID) || {};
        const liveStockProduct = mergedLiveStockProductCorporate.find(p => p.itemID === product.itemID) || {};
        return {
          ...product,
          tradeQty: tradeProduct.tradeQty || 0,
          tradeoffer: tradeProduct.tradeoffer || 0,
          offerProduct: tradeProduct.offerProduct || 0,
          LiveStockQty: liveStockProduct.LiveStockQty || 0,
          NetStockQty: liveStockProduct.NetStockQty || 0
        };
      });

      console.log('finalProductList :>> ', finalProductListCorporate);

      return {
        ...state,
        productList: finalProductListCorporate
      };


    case Types.CHECK_DEMAND_LIVE_STOCK:
      console.log('action.payload.data[0] :>> ', action.payload.data[0]);
      const LiveData = action.payload.data[0];
      console.log('LiveData :>> ', LiveData);
      const cloneProduct = state.productList.slice();
      console.log('cloneProduct', cloneProduct)

      for (let i = 0; i < cloneProduct.length; i++) {
        if (cloneProduct[i].itemID == LiveData?.productId) {
          cloneProduct[i].LiveStockQty = LiveData?.liveProductStock
          cloneProduct[i].NetStockQty = LiveData?.netProductStock
        }
      }
      return {
        ...state,
        productList: cloneProduct,
      };

    case Types.CHECK_DEMAND_LIVE_STOCK_EDIT:
      console.log('action.payload.data[0] :>> ', action.payload.data[0]);
      const editLiveData = action.payload.data[0];
      console.log('editLiveData :>> ', editLiveData);
      const cloneeditProduct = state.productList.slice();
      console.log('editLiveData', cloneeditProduct)

      for (let i = 0; i < cloneeditProduct.length; i++) {
        if (cloneeditProduct[i].itemID == editLiveData?.productId) {
          cloneeditProduct[i].LiveStockQty = editLiveData?.liveProductStock
          cloneeditProduct[i].NetStockQty = editLiveData?.netProductStock
        }
      }
      return {
        ...state,
        productList: cloneeditProduct,
      };



    case Types.CLEAR_PRODUCT_LIST_DEMAND:

      return {
        ...state,
        productList: [],
        productQuantity: 0,
        productCost: 0

      };


    case Types.GET_SALES_TRADE_OFFER_PRODUCT_LIST_ENTRY:
      console.log('123deliveryaction.payload.data :>>', action.payload.data?.allReport);
      return {
        ...state,
        tradeofferEntryPage: action.payload.data,

      };

    case Types.GET_DEMAND_LIVE_PRODUCT_STOCK:
      console.log('123deliveryaction.payload.data :>>', action.payload.data?.allReport);
      return {
        ...state,
        demandliveStock: action.payload.data,

      };
    case Types.GET_DEMAND_LIVE_PRODUCT_STOCK_EDIT:

      const UpdateProductList = Array.isArray(state.productList) ? [...state.productList] : [];
      console.log('UpdateProductList :>> ', UpdateProductList);
      const livestocklist = action.payload.data == null ? [] : action.payload.data;
      console.log('livestocklist :>> ', livestocklist);

      const mergedupdatedProductList = UpdateProductList.map(product => {
        const matchingProduct = livestocklist.find(entry => entry.producrId === product.itemID);

        const liveProductStock = matchingProduct ? matchingProduct.liveProductStock : 0;
        const netProductStock = matchingProduct ? matchingProduct.netProductStock : 0;

        return {
          ...product,
          LiveStockQty: liveProductStock,
          netProductStock: netProductStock,

        };
      });
      console.log('mergedupdatedProductList :>> ', mergedupdatedProductList);

      const combinedEditList = [
        ...mergedupdatedProductList,

      ];


      const edituniqueProducts = combinedEditList.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.itemID === value.itemID
        ))
      );

      const finalEditProductList = edituniqueProducts.map(product => {
        const liveStockProduct = mergedupdatedProductList.find(p => p.itemID === product.itemID) || {};
        console.log('liveStockProduct :>> ', liveStockProduct);
        return {
          ...product,
          NetStockQty: liveStockProduct.netProductStock || 0,
          LiveStockQty: liveStockProduct.LiveStockQty || 0 ,
        };
      });

      return {
        ...state,
        productList: finalEditProductList,

      };


    // case Types.GET_SALES_TRADE_OFFER_PRODUCT_LIST_ENTRY:
    // console.log('product - action.payload.data :>> ', action.payload.data);
    // const tradeproductList = action.payload.data == null ? [] : action.payload.data;
    // const tradeSoWise = Array.isArray(state.productList) ? [...state.productList] : [];

    // console.log('tradeSoWise', state.productList);
    // const mergedtradeProductList = tradeproductList.map(product => {
    //   const matchingProduct = tradeSoWise.find(entry => entry.itemID === product.producrId);
    //   const TradeQty = matchingProduct ? matchingProduct.tradeCtn : 0;
    //   const TradeOffer = matchingProduct ? matchingProduct.tradeOff : 0;
    //   return {
    //     ...product,
    //     TradeQty: TradeQty,
    //     TradeOffer: TradeOffer
    //   };
    // });
    // console.log('mergedProductList :>> ', mergedtradeProductList);
    // return {
    //   ...state,
    //   productList: mergedtradeProductList
    // };


    case Types.GET_SALES_APPROVED_SALES_REPORT:
      return {
        ...state,
        salesApprovedList: action.payload.data == null ? [] : action.payload.data,
        approvedLoading: action.payload.isLoading,
      };
    case Types.GET_SALES_APPROVED_DETAILS:
      return {
        ...state,
        salesApprovedDetails: action.payload.data,
        approvedLoading: action.payload.isProductLoading,
      };
    case Types.GET_SALES_PRODUCT_DEMAND_LIST:
      return {
        ...state,
        demandlist: action.payload.data,
      };
    case Types.GET_SALES_PRODUCT_DEMAND_LIST_WITH_BALANCE:
      return {
        ...state,
        demandlistWithBalance: action.payload.data,
        demandlistWithBalanceLoading: action.payload.isLoading,
      };
    case Types.GET_SALES_PRODUCT_DEMAND_LIST:
      return {
        ...state,
        giftlist: action.payload.data,
      };
    case Types.GET_CUSTOMER_PRODUCT_DELIVERY:
      return {
        ...state,
        productDelivery: action.payload.data,
        isPProductLoading: action.payload.isPProductLoading,
      };
    case Types.GET_PRIMARY_DELIVERY_DISTRIBUTOR_VERTICLE:
      return {
        ...state,
        verticlePridistributorReport: action.payload.data,
        verticlePridistributorReportLoading: action.payload.status,

      };
    case Types.GET_PRODUCT_SELECTED:
      const productInput = { ...state.itemDataInput };
      productInput.itemName = "";
      const fetchProduct = state.selectedProduct;
      fetchProduct.push(action.payload);
      return {
        ...state,
        selectedProduct: fetchProduct,
        filterProduct: [],
        itemDataInput: productInput,
      };
    case Types.GET_PRODUCT_DELETE:
      const delteData = state.selectedProduct;
      delteData.splice(action.payload, 1);
      return {
        ...state,
        selectedProduct: delteData,
      };
    case Types.SALES_PRODUCT_SUBMIT:
      return {
        ...state,
        submitLoading: false,
        productSubmitStatus: action.payload.status,
        availableBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        submitsecondaryStatus: action.payload.status,
      };


    case Types.SALES_PRODUCT_SUBMIT_FAILED:
      const LiveDataAll = action.payload?.data;
      const cloneProductAll = [...state.productList];
      console.log('cloneProductAll ', cloneProductAll);
      console.log('LiveDataAll :>> ', LiveDataAll);

      cloneProductAll.forEach(product => {
        const matchedProduct = LiveDataAll?.find(liveProduct => liveProduct.producrId === product.itemID);
        if (matchedProduct) {
          product.LiveStockQty = matchedProduct.liveProductStock;
          product.NetStockQty = matchedProduct.netProductStock;
        }
      });

      return {
        ...state,
        productList: cloneProductAll,
        submitLoading: false,
        productSubmitStatus: action.payload.status,
        availableBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        submitsecondaryStatus: action.payload.status,
      };




    case Types.SALES_PRODUCT_UPDATE:
      return {
        ...state,
        productUpdateStatus: action.payload.status,
        NegativeStock: action.payload.data,
      };
    case Types.GET_SALES_LIST_EDIT_DATA:
      return {
        ...state,
        salesEditInfo: action.payload,
      };
    case Types.PRODUCT_SUBMIT_STATUS_REMOVE:
      return {
        ...state,
        productSubmitStatus: false,
      };
    case Types.GET_SALES_ORDER_DETAILS:
      let totalSum = 0;
      let totalQuantity = 0;

      for (let i = 0; i < action.payload.data.length; i++) {
        totalSum += action.payload.data[i].totalPrice;
        totalQuantity += action.payload.data[i].itemQuentity;
      }
      return {
        ...state,
        salesDetails: action.payload.data,
        totalSalesDetailsAmount: totalSum,
        totalDetQuantity: totalQuantity,
      };
    case Types.GET_CUSTOMER_PROD_DETAILS:
      // let totalSum = 0;
      // let totalQuantity = 0;

      // for (let i = 0; i < action.payload.data.length; i++) {
      //   totalSum += action.payload.data[i].totalPrice;
      //   totalQuantity += action.payload.data[i].itemQuentity;
      // }
      return {
        ...state,
        customerDeliveryProd: action.payload.data,
        isDeDetails: action.payload.isDeDetails,
        // totalSalesDetailsAmount: totalSum,
        // totalDetQuantity: totalQuantity,
      };
    case Types.GET_SALES_ORDER_UPDATE_DETAILS:

      return {
        ...state,
        productList: action.payload.data,
      };
    case Types.CLEAR_REMOTE_SALES_DATA:
      return {
        ...state,
        availableBalance: 0,
        restBalance: 0,
        creditLimit: 0,
        productCost: 0,
        countProduct: 0,
        productQuantity: 0,
        salesApprovedList: [],
        demandlist: [],
        // productSubmitStatus:false

      };
    case Types.DELETE_SALES_TITEM:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.GET_CUSTOMER_BALANCE:
      console.log('efaction.payload.data', action.payload.data)
      let submitData = { ...state.submitData };

      submitData.intCustomer = action.payload.data.cusid;
      submitData.monCustBalance = action.payload.data.availableBalance;
      submitData.coaid = action.payload.data.intcoaid;
      submitData.intsalesofficeid = action.payload.data.intsalesoffid;
      submitData.disPointID = action.payload.data.disPointID;
      submitData.delPointID = action.payload.data.delPointID;
      submitData.distribCat = action.payload.data.distribCat;


      return {
        ...state,
        availableBalance: action.payload.data.availableBalance,
        creditLimit: action.payload.data.moncreditlimit,
        balanceLoader: action.payload.isLoading,
        restBalance: action.payload.data.availableBalance,
        distributionSalesGroupId: action.payload.data.disSalesGroupId,
        submitData,
      };
    case Types.CHANGE_PRODUCT_QUANTITY:

      let quantityvalue = parseInt(action.payload.value);
      if (isNaN(quantityvalue)) {
        quantityvalue = 0;
      }
      const product = state.productList.slice();
      console.log('quantityvalue :>> ', quantityvalue);
      console.log('action.payload123 :>> ', action.payload);

      const avaiblebalance = state.availableBalance;
      let sumItem = 0;
      let restBalance = 0;
      let rest;
      let productQuantity = 0;
      for (let i = 0; i < product.length; i++) {
        if (product[i].itemID === action.payload.item.itemID) {
          product[i].itemQuentity = quantityvalue;
          //product[i].itemQuentity =  quantityvalue ;
          product[i].salesGroupId = action.payload.item.salesGroupId;
          product[i].totalPrice =
            product[i].itemQuentity * product[i].itemPrice;
        }
        sumItem += product[i].itemQuentity * product[i].itemPrice;
        restBalance = avaiblebalance - sumItem;
        productQuantity += product[i].itemQuentity;
      }

      console.log('productred :>> ', product);

      return {
        ...state,
        productList: product,
        productCost: sumItem,
        productQuantity: productQuantity,
        restBalance
      };
    case Types.CHANGE_PRODUCT_UPDATE_QUANTITY:
      let quantityUpdatevalue = parseInt(action.payload.value);
      if (isNaN(quantityUpdatevalue)) {
        quantityvalue = 0;
      }

      const productUpdate = state.productList.slice();
      let sumItemUpdate = 0;
      let uprestBalance = 0;
      let productUpQuantity = 0;
      const Upavaiblebalance = state.availableBalance;
      for (let i = 0; i < productUpdate.length; i++) {
        if (productUpdate[i].itemID === action.payload.item.itemID) {
          productUpdate[i].itemQuentity = quantityUpdatevalue;
          productUpdate[i].salesGroupId = action.payload.item.salesGroupId;
          productUpdate[i].totalPrice =
            productUpdate[i].itemQuentity * productUpdate[i].itemPrice;


        }

        sumItemUpdate +=
          productUpdate[i].itemQuentity * productUpdate[i].itemPrice;
        uprestBalance = Upavaiblebalance - sumItemUpdate;
        productUpQuantity += productUpdate[i].itemQuentity


      }

      return {
        ...state,
        productList: productUpdate,
        productCost: sumItemUpdate,
        restBalance: uprestBalance,
        productQuantity: productUpQuantity,


      };
    case Types.GET_DISTRIBUTORLISTPERMISSION_FOR_SMS:
      return {
        ...state,
        distibListforsms: action.payload.data,
        smsreportLoading: action.payload.isLoading
      };
    case Types.SMS_SEND_FROM_IMS:
      return {
        ...state,

        smsStatus: action.payload.status

      };

    case Types.CLEAR_LIST:
      return {
        ...state,
        productList: [],

      };

    case Types.GET_CUSTOMER_BALANCE_NEW:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        balanceData: action.payload.data,
        status: action.payload.status,
      };
    case Types.GET_CUSTOMER_ALL_TYPES_BALANCE:
      console.log('action.payload.data123 :>> ', action.payload.data);
      return {
        ...state,
        aLLbalanceDataLoading: action.payload.isLoading,
        aLLbalanceData: action.payload.data[0],

      };

    case Types.GET_SALES_APPROVE_PENDING_VALUE:
      console.log('salesApproveDataList :>> ', action.payload.data);
      return {
        ...state,
        salesApproveDataLoading: action.payload.isLoading,
        salesApproveData: action.payload.data?.salesSum,
        salesApproveDataList: action.payload.data?.salesList,
        status: action.payload.status,
      };


    case Types.GET_ALL_PINDING_PRODUCT_LIST:

      return {
        ...state,
        allPendingList: action.payload.data,
        allPendingListLoading: action.payload.isProductLoading,

      };

    default:
      break;
  }
  return newState;
};

export default RemoteSalesReducer;

const getDistributor = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.intcusid,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};

const getSalesGroupDistributor = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getDamageCustomerbyUnit = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.intcusid,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};

