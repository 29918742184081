import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  getUpdateSalesGroupProductList,
  EditDateGet,
  NewproductUpdateInputQuantity,
  NewsalesUpdate,
  CheckDemandLiveStockEdit,
  CheckDemandLiveStockEditWithDemand,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { GetDemandDecisionStatus } from "../../_redux/actions/Delivery/DeliveryAction";
import { salesUpdate } from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";

const RemoteSalesEditWithBalance = ({ itemDetailnIfo1, selectedZone, selectedDivision, selectedArea, selectedTerritory }) => {

  const dispatch = useDispatch();

  const history = useHistory();

  const { handleSubmit } = useForm();

  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled, setDisabled] = useState(false);
  const salesUpdateData = useSelector((state) => state.remoteSales.productList);
  const NegativeStock = useSelector((state) => state.remoteSales.NegativeStock);
  console.log('salesUpdateData :>> ', salesUpdateData);

  const productCost = useSelector((state) => state.remoteSales.productCost);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);


  const [decision, setDecision] = useState(false);

  useEffect(() => {
    const fetchDemandDecisionStatus = async () => {
      try {
        const data = await GetDemandDecisionStatus();
        if (data.data?.isDateMatch !== undefined) {
          setDecision(data.data?.isDateMatch);
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    };

    fetchDemandDecisionStatus();
  }, []);


  useEffect(() => {
    dispatch(EditDateGet(itemDetailnIfo1));
    dispatch(getUpdateSalesGroupProductList(itemDetailnIfo1?.orderId, itemDetailnIfo1?.salesGroupId));
  }, []);


  const changeProductQuantity = (item, value) => {

    if (decision == false) {
      dispatch(NewproductUpdateInputQuantity(item, value));
      dispatch(CheckDemandLiveStockEditWithDemand(item?.itemID, value, itemDetailnIfo1?.orderId));
    } else {
      dispatch(NewproductUpdateInputQuantity(item, value));
    }



  };


  const onSubmit = async (e) => {
    setDisabled(true);

    if (decision == false) {
      dispatch(NewsalesUpdate(itemDetailnIfo1?.orderId, salesUpdateData, selectedZone, selectedDivision, selectedArea, selectedTerritory));
    } else {
      dispatch(salesUpdate(itemDetailnIfo1?.orderId, salesUpdateData, selectedZone, selectedDivision, selectedArea, selectedTerritory));
    }

  };


  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-3">
          <div >
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">Distributor Name : {itemDetailnIfo1?.cusName}</strong>
                    <br />
                    Distributor Code : {itemDetailnIfo1?.cusCode}
                    <br />
                    <br />
                    Invoice : {itemDetailnIfo1?.orderCode}
                    <br />

                  </address>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="container-fluid mb-3  containerFixed" style={{ padding: "0 15px" }}>
          <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

            <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
              <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>BALANCE</h5>

              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Available Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}> {numberFormatDecemal(parseFloat(itemDetailnIfo1?.generalBalance).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Credit Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{itemDetailnIfo1?.creditLimit}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Pending Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{itemDetailnIfo1?.pendingBalance}</span>
              </div>


              <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Net Available Balance</span>
                <span
                  style={{
                    float: "right",
                    fontSize: "13px",
                    color: (() => {

                      const totalSum = ((itemDetailnIfo1?.generalBalance + itemDetailnIfo1?.creditLimit - itemDetailnIfo1.pendingBalance))

                      return totalSum < 0 ? 'red' : '#050f26';

                    })(),
                    fontWeight: '500',
                  }}
                >
                  {(() => {

                    const totalSum = (parseFloat(itemDetailnIfo1?.generalBalance + itemDetailnIfo1?.creditLimit - itemDetailnIfo1.pendingBalance).toFixed(2))


                    return numberFormatDecemal((totalSum));
                  })()}
                </span>


              </div>

              <span className="float-right warning-msg-entry-page">( N.B : Without Damage Balance )</span>
            </div>


            <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
              <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>DEMAND</h5>

              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Quantity</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(countProduct)) ? '0.00' : numberFormatDecemal(parseFloat(countProduct).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Product Value</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>-</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>-</span>
              </div>

              <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Demand Value</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                  {isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}
                </span>
              </div>
            </div>

          </div>
        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData?.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Live Stock</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {salesUpdateData && salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.NetStockQty}</td>

                      {decision === false && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autocomplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={item.itemQuentity}
                              onChange={(e) =>
                                changeProductQuantity(item, e.target.value)
                              }
                            />
                          </Form.Group>
                          {item.LiveStockQty < 0 && (
                            <small className="validation-symbol">
                              Sorry!! Stock is not available
                            </small>
                          )}
                        </td>
                      )}

                      {decision === true && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autocomplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={item.itemQuentity}
                              onChange={(e) =>
                                changeProductQuantity(item, e.target.value)
                              }
                            />
                          </Form.Group>
                        </td>
                      )}

                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  // disabled={disabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();

                  }}
                  type="button"
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>

                {
                  NegativeStock?.length > 0 && (
                    <p style={{ color: 'red' }}>
                      Sorry!! Stock is not available. Revised these products:{" "}
                      {Array.isArray(NegativeStock) && NegativeStock.length > 0
                        ? NegativeStock.map((product, index) => (
                          <span key={index}>
                            <span style={{ color: 'black' }}>{product.producrId}</span>
                            {index !== NegativeStock.length - 1 && ", "}
                          </span>
                        ))
                        : "No products"}
                    </p>
                  )
                }
              </div>
            </div>
          ) : null}
        </div>

      </form>
    </>
  );
};

export default RemoteSalesEditWithBalance;
