import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesListDetails,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import ReactToPrint from "react-to-print";
import { Dropdown, Card } from "react-bootstrap";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const RemoteSalesDetails = ({ handleClose, itemID, itemDetailnIfo }) => {


  const ItemDetails = useSelector((state) => state.remoteSales.salesDetails);


  const ref = React.createRef();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSalesListDetails(itemID));
  }, []);
  return (
    <>
      {ItemDetails && (
        <div className="container">
          <div className="col-md-12">
            <div className="invoice">
              <div className="export text-right">
                <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    className="btn btn-sm"
                    variant="light text-primary"
                    id="dropdown-basic"
                  >
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Pdf
                      targetRef={ref}
                      filename="Certificate Type.pdf"
                      x={0.5}
                      y={0.5}
                      scale={0.1}
                    >
                      {({ toPdf }) => (
                        <Dropdown.Item onClick={toPdf}>
                          <i class="far fa-file-pdf"></i>
                          <span className="ml-3">Pdf</span>
                        </Dropdown.Item>
                      )}
                    </Pdf> */}

                    {/* <Dropdown.Item>
                      <i class="far fa-file-excel mt-1"></i>
                      {
                        <ReactHTMLTableToExcel
                          className="excelBtn ml-1"
                          table="table-to-xls"
                          filename="Certificate Type"
                          sheet="tablexls"
                          buttonText="Excel"
                        />
                      }
                    </Dropdown.Item> */}

                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item href="#/action-1" onClick={window.print}>
                          <i class="fas fa-print"></i>
                          <span className="ml-2">Print</span>
                        </Dropdown.Item>
                      )}
                      content={() => ref.current}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div ref={ref} id="printDiv">
                <h4 className="text-left">
                  {itemDetailnIfo?.custName}
                </h4>
                <div>

                  <div className="invoice-date">

                    <div className="invoice-detail">
                      Invoice No:
                      {itemDetailnIfo?.orderCode}
                    </div>
                  </div>
                  <div className="invoice-content item-add-table">
                    <table className="table table-responsive">
                      <table className="table table-bordered bg-warning-o-10" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Item Id</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th className="text-right" width="10%">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ItemDetails && ItemDetails.map((item, index) => (
                            <tr key={index}>
                              <td style={{ width: '10%' }}>{item.itemID}</td>
                              <td className="text-left" style={{ width: '60%' }}>{item.itemName}</td>
                              <td className="text-left" style={{ width: '30%' }}>{numberFormatDecemal(item.itemQuentity)}</td>
                              <td className="text-right">{numberFormatDecemal(item.totalPrice)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan="2" className="text-right">Total</th>
                            <th className="text-left">{ItemDetails && numberFormatDecemal(ItemDetails.reduce((total, item) => total + item.itemQuentity, 0))}</th>
                            <th className="text-right">{ItemDetails && numberFormatDecemal(parseFloat(ItemDetails.reduce((total, item) => total + item.totalPrice, 0)).toFixed(2))}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </table>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      )}
      <Button
        variant="secondary"
        classNameNameName="float-right"
        onClick={handleClose}
      >
        Close
      </Button>
    </>
  );
};

export default RemoteSalesDetails;
