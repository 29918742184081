import React, { useEffect, useState } from "react";
import RemoteSalesAdd from "../../../information/components/RemoteSales/RemoteSalesAdd";
import { GetCurrentDateTime, GetDemandSchaduleTime } from "../../../information/_redux/actions/Delivery/DeliveryAction";
import { FcAlarmClock } from "react-icons/fc";
import CorporateSalesEntryWithLiveStock from "../../../information/components/RemoteSales/CorporateSalesEntryWithLiveStock";

const CorporateSalesAddContainerWithLiveStock = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [eventTriggered, setEventTriggered] = useState(false);

  const [currentDate, setCurrentDate] = useState('');
  const [SystemDate, setSystemDate] = useState('');

  const [schedule, setSchedule] = useState({
    startTimeH: 0,
    startTimeM: 0,
    endTimeH: 0,
    endTimeM: 0
  });

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        const data = await GetDemandSchaduleTime();

        const currentData = await GetCurrentDateTime();
        setCurrentDate(currentData.data?.currentDate);

        if (
          data.data?.startTimeH && data.data?.startTimeM &&
          data.data?.endTimeH && data.data?.endTimeM
        ) {
          setSchedule({
            startTimeH: data.data?.startTimeH,
            startTimeM: data.data?.startTimeM,
            endTimeH: data.data?.endTimeH,
            endTimeM: data.data?.endTimeM
          });
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    };

    fetchScheduleData();
  }, []);

  const calculateTimeLeft = () => {
    const now = new Date();
    setSystemDate(now);

    const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    const startTime = schedule.startTimeH * 3600 + schedule.startTimeM * 60;
    const endTime = schedule.endTimeH * 3600 + schedule.endTimeM * 60;

    if (currentTime >= startTime && currentTime <= endTime) {
      if (!eventTriggered) {
        setEventTriggered(true);
      }
      setTimeLeft(endTime - currentTime);
    } else if (currentTime < startTime) {
      const timeToStart = startTime - currentTime;
      setTimeLeft(timeToStart);
    } else {
      setTimeLeft(0);
      setEventTriggered(false);
    }
  };

  useEffect(() => {
    if (
      schedule.startTimeH && schedule.startTimeM &&
      schedule.endTimeH && schedule.endTimeM
    ) {
      calculateTimeLeft();

      const intervalId = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [schedule, eventTriggered]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeLeft);

  const text = " 🚀 Demand will be available from 6:00 AM to 5:00 PM 🚀 Mobile / Computer time needs to be updated.";

  const repeatedText = Array(1).fill(text).join('  |  ');
  const currentDateTime = new Date(currentDate);
  const systemDateTime = new Date(SystemDate);

  const isCurrentDateBeforeSystemDate = currentDateTime < systemDateTime;

  return (
    <div className="container-fluid">
      <div>
        {eventTriggered && (
          <div className="timer-container mb-2 mt-2">
            <span className="timer-label">Time Left : </span>
            <div className="timer-text">
              <div className="timer-segment hours">
                <span>{hours.toString().padStart(2, "0")}</span>
              </div>
              <span>:</span>
              <div className="timer-segment minutes">
                <span>{minutes.toString().padStart(2, "0")}</span>
              </div>
              <span>:</span>
              <div className="timer-segment seconds">
                <span>{seconds.toString().padStart(2, "0")}</span>
              </div>
            </div>
          </div>
        )}

        <div>
          {eventTriggered === false && (
            <div
              style={{
                backgroundColor: '#FA8072',
                padding: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <FcAlarmClock style={{ marginRight: '10px', fontSize: '24px' }} />

              <marquee
                behavior="scroll"
                direction="left"
                style={{
                  flexGrow: 1,
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                }}
              >
                <span
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    marginRight: '20px',
                  }}
                >
                  {repeatedText}
                </span>
              </marquee>
            </div>
          )}
        </div>

        {isCurrentDateBeforeSystemDate && eventTriggered && <CorporateSalesEntryWithLiveStock />}
      </div>
    </div>
  );
};

export default CorporateSalesAddContainerWithLiveStock;
