import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../../_redux/actions/RemoteSales/DistributorReportAction";
import { currentdate, setDateFromOldDate } from "../../../../../../domains/CCO/utils/DateHelper";
import SimpleSalesType from "../../SalesType/SimpleSalesType";
import { subSalesApprovedReport } from "../../../_redux/actions/RemoteSales/SuperDepoAction";
import SubSalesApprovedDetails from "./SubSalesApprovedDetails";
import { cleanRemoteSalesData, getSalesList, salesDelete } from "../../../_redux/actions/RemoteSales/BreadRemoteSalesAction";



const SubSalesApprovedReport = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.itemList.approvedLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const salesApprovedList = useSelector((state) => state.remoteSalesbread.salesApprovedList);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();


  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanRemoteSalesData());
  }, [])
  // items list with pagination
  useEffect(() => {
    initialData();
  }, [startDate, toDate]);

  const initialData = async () => {
    let zoneData = await getZoneList();
    let curDate = currentdate();
    let curDateFormate = currentdate();
    // setToDate(curDateFormate);
    // setStartDate(curDate);
    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };



  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.inVId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);


  const handleChangeTodate = ({ currentTarget: input }) => {

    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const showReport = () => {
    dispatch(subSalesApprovedReport(startDate, toDate, selectArea, selectTerritory, salesType));
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }


  

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        {/* <div className="container"> */}
        {/* <SalesApprovedReportFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sales Demand Approved Report</h3>
            </div>
          </div>
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>
        <div className="form-group row">
          <div className="col">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  divisionList(option.value);
                  setSelectZone(option.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("intDistributorId", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Area</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  // setSelectArea(option.value);
                  // dispatch(getSalesList(option.value));

                  getTerritoryData(option.value)
                  setSelectArea(option.value);
                 
                  
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    dispatch(getSalesList(option.value, salesType));
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>



          <div className="col">
            <label className="form-label"> From Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Deadweight"
              name="fromDate"
              className="fromStyle"
              onChange={handleChange}
              value={startDate}
            />

          </div>
          <div className="col">
            <label className="form-label"> To Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Todate"
              name="todate"
              value={toDate}
              className="fromStyle"
              onChange={handleChangeTodate}
            />
          </div>
          <div className="col mt-4">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  {/* <th scope="col">Department</th> */}
                  {/* <th scope="col">ORDER ID</th> */}
                  <th scope="col">DISTRIBUTOR ID</th>
                  <th scope="col">DISTRIBUTOR NAME</th>
                  <th scope="col">INVOICE NO</th>
                  <th scope="col">INVOICE DATE</th>
                  <th scope="col">ORDER QUANTITY</th>
                  <th scope="col">BONUS QUANTITY</th>
                  <th scope="col">APPROVED QUANTITY</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {salesApprovedList.length > 0 &&
                  salesApprovedList.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.distId}</td>
                      <td>{item.distName}</td>
                      <td>{item.invCode}</td>
                      <td>{setDateFromOldDate(item?.invDate)}</td>
                      <td>{item.totalOrderQuentity}</td>
                      <td>{item.bonusQty}</td>
                      <td>{item.totalApprovedQuentity}</td>
                      <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!isLoading && salesApprovedList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Approved List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SubSalesApprovedDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SubSalesApprovedReport;
