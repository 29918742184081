import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  getUpdateSalesGroupProductList,
  getCustomerSalesGroupInfo,
  GetNewCustomerBalanceByID,
  CheckDemandLiveStockEdit,
  NewproductUpdateInputQuantity,
  NewsalesUpdate,
  salesUpdate,
  GetAllPendingProductList,
  CheckDemandLiveStockEditWithDemand
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { GetDemandDecisionStatus } from "../../_redux/actions/Delivery/DeliveryAction";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const RemoteSalesEditGeneral = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, code, name } = useParams();
  const { handleSubmit } = useForm();

  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled, setDisabled] = useState(false);
  const salesUpdateData = useSelector((state) => state.remoteSales.productList);
  const NegativeStock = useSelector((state) => state.remoteSales.NegativeStock);
  console.log('NegativeStock :>> ', NegativeStock);
  console.log('salesUpdateData :>> ', salesUpdateData);
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);
  const pendingList = useSelector((state) => state.remoteSales.allPendingList);

  const [decision, setDecision] = useState(false);

  useEffect(() => {
    const fetchDemandDecisionStatus = async () => {
      try {
        const data = await GetDemandDecisionStatus();
        if (data.data?.isDateMatch !== undefined) {
          setDecision(data.data?.isDateMatch);
        }
      } catch (error) {
        console.error("Error fetching schedule data:", error);
      }
    };

    fetchDemandDecisionStatus();
  }, []);


  useEffect(() => {
    if (productUpdateStatus) {
      setTimeout(() => { history.go(0) }, 2000);
    }
  }, [productUpdateStatus]);


  useEffect(() => {
    dispatch(GetNewCustomerBalanceByID(code));

    dispatch(GetAllPendingProductList(code));
    getInitialData();
  }, []);


  const getInitialData = async () => {
    var salesGroupData = await getCustomerSalesGroupInfo(code);
    if (salesGroupData?.data != null) {
      dispatch(getUpdateSalesGroupProductList(id, salesGroupData?.data.disSalesGroupId));


    }
  }

  const changeProductQuantity = (item, value) => {

    if (decision == false) {

      dispatch(NewproductUpdateInputQuantity(item, value));
      dispatch(CheckDemandLiveStockEditWithDemand(item?.itemID, value, id));
    } else {
      dispatch(NewproductUpdateInputQuantity(item, value));
    }

  };


  const handleDemandSubmit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: <span className="confirm-alert-title">Confirm To Update</span>,
      buttons: [
        {
          label: "Yes",
          onClick: () => onSubmit(e),
          className: "approve-yes-btn",
        },
        {
          label: "No",
          className: "approve-no-btn",
        },
      ],
    });
  };





  const onSubmit = async (e) => {
    setDisabled(true);

    if (decision == false) {
      dispatch(NewsalesUpdate(id, salesUpdateData));
    } else {
      dispatch(salesUpdate(id, salesUpdateData));
    }
  };

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-3">
          <div >
            <h3 className="mb-0 pb-0">General Sales Update</h3>

          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="">
              <div className="">
                <div className="">
                  <address className="m-b-1 text-center">
                    <h3><strong className=" text-center">{name}</strong></h3>
                    <h3>{code}</h3>
                  </address>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container mb-3 containerFixed">
          <div className="row">

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left">
                  <p>Available Balance For Demand</p>
                </div>
                <div >
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {isNaN(parseFloat((NewCustomerBalance?.generaL_BALANCE || 0) - (
                      Array.isArray(pendingList) && pendingList.length > 0
                        ? pendingList.reduce((sum, x) => sum + (x.pendingValue || 0), 0)
                        : 0
                    ))) ? "0.00" : parseFloat((NewCustomerBalance?.generaL_BALANCE || 0) - (
                      Array.isArray(pendingList) && pendingList.length > 0
                        ? pendingList.reduce((sum, x) => sum + (x.pendingValue || 0), 0)
                        : 0
                    )).toFixed(2)}</h4>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  <p>Credit Limit</p>
                </div>
                <div >
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  <p>Total Product Cost</p>
                </div>
                <div >
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  <p>Total Quantity</p>
                </div>
                <div >
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData?.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Live Stock</th>
                  <th>Quantity</th>
                </thead>
                <tbody>
                  {salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.NetStockQty}</td>

                      {decision === false && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autocomplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={item.itemQuentity}
                              onChange={(e) =>
                                changeProductQuantity(item, e.target.value)
                              }
                            />
                          </Form.Group>
                          {item.LiveStockQty < 0 && (
                            <small className="validation-symbol">
                              Sorry!! Stock is not available
                            </small>
                          )}
                        </td>
                      )}

                      {decision === true && (
                        <td>
                          <Form.Group>
                            <Form.Control
                              autocomplete="off"
                              className="formHeight"
                              type="number"
                              placeholder="Quantity"
                              name="itemName"
                              value={item.itemQuentity}
                              onChange={(e) =>
                                changeProductQuantity(item, e.target.value)
                              }
                            />
                          </Form.Group>
                        </td>
                      )}





                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  // disabled={disabled}
                  onClick={(e) => handleDemandSubmit(e)}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/remoteSales/general-sales/list";
                  }}
                  type="button"
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>
                
                {
                  NegativeStock?.length > 0 && (
                    <p style={{ color: 'red' }}>
                      Sorry!! Stock is not available. Revised these products:{" "}
                      {Array.isArray(NegativeStock) && NegativeStock.length > 0
                        ? NegativeStock.map((product, index) => (
                          <span key={index}>
                            <span style={{ color: 'black' }}>{product.producrId}</span>
                            {index !== NegativeStock.length - 1 && ", "}
                          </span>
                        ))
                        : "No products"}
                    </p>
                  )
                }


              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default RemoteSalesEditGeneral;
