import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  cleanRemoteSalesData,
  getSalesList,
  showApprovedReport,
  showApprovedReportFieldForce,
} from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";

import { Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

import SimpleSalesType from "../SalesType/SimpleSalesType";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { setDateFromOldDate } from "../../../../master/PrintDwopdown/DateHelper";
import SalesApprovedReportDetails from "./SalesApprovedReport/SalesApprovedReportDetails";
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import SalesApprovedReportDetailsForFeild from "./SalesApprovedReport/SalesApprovedReportDetailsForFeild";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { FaSearch } from "react-icons/fa";

const DemandApprovedByFieldForce = () => {

  const isLoading = useSelector((state) => state.remoteSalesbread.approvedLoading);

  const salesApprovedList = useSelector((state) => state.remoteSalesbread.salesApprovedList);
  console.log('salesApprovedList :>> ', salesApprovedList);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const { setValue } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanRemoteSalesData());
  }, [])

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {

        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.inVId);
    setItemDetailShow(true);
  };

  const inputField = React.useRef(null);

  const handleChangeTodate = ({ currentTarget: input }) => {

    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const showReport = () => {
    dispatch(showApprovedReportFieldForce(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, salesType, ReportType));
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);


  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [ReportType, setReportType] = useState(1);

  const [reportList, setReportList] = useState([

    {
      id: 1,
      type: 'Approved'
    },
    {
      id: 2,
      type: 'UnApproved'
    }
  ]);

  const reportTypeDdl = () => {
    let options = [];
    if (reportList?.length > 0) {
      reportList.forEach((item) => {
        let itemData = {
          value: item.id,
          label: item.type,
        };

        options.push(itemData);
      });
    }
    return options;
  };





  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">

        <div className="row mt-2">
          <div className="col-md-9">
            <div className=" p-2">
              <h3 className="mb-0 pb-0">Demand Approved Report</h3>
            </div>
          </div>
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>

        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> From Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                className="fromStyle"
                onChange={handleChange}
                value={startDate}
              />
            </Form.Group>

          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option);
                  dispatch(getSalesList(option?.value, salesType));
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Report Type</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={reportTypeDdl()}
                value={reportTypeDdl().find(option => option.value === ReportType)}
                onChange={(option) => {
                  setReportType(option?.value);
                }}
              />
            </Form.Group>
          </div>



          <div className="col-lg-3 mt-6 mb-3">
            {/* <button className="button-621" onClick={() => showReport()} >  Show Report</button> */}
            <Button size="sm" onClick={() => showReport()}><FaSearch /> Show Report</Button>
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">

            <div
              className="shortstickyTable"
              style={{ height: salesApprovedList?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table">
                <thead>
                  <tr>
                    {/* <th scope="col">Department</th> */}
                    {/* <th scope="col">ORDER ID</th> */}
                    <th scope="col">DISTRIBUTOR ID</th>
                    <th scope="col">DISTRIBUTOR NAME</th>
                    <th scope="col">INVOICE NO</th>
                    <th scope="col">INVOICE DATE</th>
                    <th scope="col">ORDER QUANTITY</th>
                    <th scope="col">ORDER VALUE</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {salesApprovedList.length > 0 &&
                    salesApprovedList.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.distId}</td>
                        <td>{item.distName}</td>
                        <td>{item.invCode}</td>
                        <td>{setDateFromOldDate(item?.invDate)}</td>
                        <td>{numberFormatDecemal(item.totalOrderQuentity)}</td>
                        <td>{numberFormatDecemal(item.totalOrderPrice)}</td>
                        <td>
                          {" "}
                          <Link onClick={() => itemDetail(item)} title="Details">
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && salesApprovedList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Approved List Not Found.
              </div>
            )}

          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SalesApprovedReportDetailsForFeild
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        
      </div>
    </>
  );
};

export default DemandApprovedByFieldForce;
