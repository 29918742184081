import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { dateTimeConvertLongStringUpdated, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const RemoteSalesAddConfirmedValueDetails = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const salesApproveDataList = useSelector((state) => state.remoteSales.salesApproveDataList);


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-1 ">
        <div className="row">
          <div className="col-md-9">
            <div className="mt-2 p-2">

              <h3 className="mb-0 pb-0">Confirmed Value List</h3>
            </div>
          </div>
        </div>

        <hr></hr>

        <div >
          <div className="react-bootstrap-table table-responsive">
            <table className="table table-head-custom table-vertical-center">
              <thead>
                <tr>
                  <th className="bg-secondary">SL</th>
                  <th className="bg-secondary">Invoice No</th>
                  <th className="bg-secondary">Invoice Date</th>
                  <th className="bg-secondary">Product Quantity</th>
                  <th className="bg-secondary">Product Total Price</th>
                </tr>
              </thead>
              <tbody>
                {salesApproveDataList && salesApproveDataList.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.invNo}</td>
                    <td>{dateTimeConvertLongStringUpdated(item.confirmedDate)}</td>
                    <td className="text-right">{numberFormatDecemal(item.prodQty)}</td>
                    <td className="text-right">{numberFormatDecemal(item.productTPrice)}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan="3" className="text-right font-weight-bold">Total</td>
                  <td className="font-weight-bold text-right">
                    {numberFormatDecemal(salesApproveDataList && salesApproveDataList.reduce((acc, item) => acc + item.prodQty, 0))}
                  </td>
                  <td className="font-weight-bold text-right">
                    {numberFormatDecemal(parseFloat(salesApproveDataList && salesApproveDataList.reduce((acc, item) => acc + item.productTPrice, 0)).toFixed(2))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoteSalesAddConfirmedValueDetails;
