import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const roles = {
  ADMIN: 'Admin',
  PROJECT_MANAGER: 'Project Manager',
  PROJECT_CONTROLLER: 'Project Controller',
};

const initialStages = {
  'Product & Design': [
    { id: '1', content: 'Task 1: Design UI' },
    { id: '2', content: 'Task 2: Create Wireframe' },
  ],
  'Development': [
    { id: '3', content: 'Task 3: Setup Backend' },
    { id: '4', content: 'Task 4: Develop Frontend' },
  ],
  'Testing': [
    { id: '5', content: 'Task 5: Write Unit Tests' },
  ],
  'Release': [
    { id: '6', content: 'Task 6: Deploy to Production' },
  ],
};

const ProjectManagementPage = () => {
  const [selectedRole, setSelectedRole] = useState(roles.ADMIN); // Default role as Admin
  const [stages, setStages] = useState(initialStages);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceStage = stages[source.droppableId];
    const destinationStage = stages[destination.droppableId];
    const [movedTask] = sourceStage.splice(source.index, 1);

    destinationStage.splice(destination.index, 0, movedTask);

    setStages({
      ...stages,
      [source.droppableId]: sourceStage,
      [destination.droppableId]: destinationStage,
    });
  };

  const canAddTasks = [roles.ADMIN, roles.PROJECT_MANAGER].includes(selectedRole);
  const canDeleteTasks = selectedRole === roles.ADMIN;
  const canMoveTasks = [roles.ADMIN, roles.PROJECT_MANAGER, roles.PROJECT_CONTROLLER].includes(selectedRole);

  const addTask = (stage) => {
    if (canAddTasks) {
      const newTask = { id: `${Date.now()}`, content: `New task for ${stage}` };
      setStages((prevStages) => ({
        ...prevStages,
        [stage]: [...prevStages[stage], newTask],
      }));
    }
  };

  const deleteTask = (taskId, stage) => {
    if (canDeleteTasks) {
      setStages((prevStages) => ({
        ...prevStages,
        [stage]: prevStages[stage].filter((task) => task.id !== taskId),
      }));
    }
  };

  return (
    <div className="pm-container">
      <h1 className="pm-header">Project Management</h1>

      {/* Dropdown for role selection */}
      <div className="role-selector">
        <label htmlFor="role-select">Select Role: </label>
        <select
          id="role-select"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value={roles.ADMIN}>Admin</option>
          <option value={roles.PROJECT_MANAGER}>Project Manager</option>
          <option value={roles.PROJECT_CONTROLLER}>Project Controller</option>
        </select>
      </div>

      {/* Full Page Section */}
      <div className="pm-fullpage-section">
        {/* Admin Section */}
        {selectedRole === roles.ADMIN && (
          <div className="pm-fullpage">
            <h2>Admin Section</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="pm-stages">
                {Object.keys(stages).map((stage) => (
                  <Droppable key={stage} droppableId={stage}>
                    {(provided, snapshot) => (
                      <div
                        className={`pm-stage ${snapshot.isDraggingOver ? 'pm-stage--dragging-over' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <h3>{stage}</h3>
                        <button className="pm-add-task-button" onClick={() => addTask(stage)}>Add Task</button>
                        {stages[stage].map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <div
                                className="pm-task"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {task.content}
                                <button
                                  className="pm-delete-task-button"
                                  onClick={() => deleteTask(task.id, stage)}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </div>
        )}

        {/* Project Manager Section */}
        {selectedRole === roles.PROJECT_MANAGER && (
          <div className="pm-fullpage">
            <h2>Project Manager Section</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="pm-stages">
                {Object.keys(stages).map((stage) => (
                  <Droppable key={stage} droppableId={stage}>
                    {(provided, snapshot) => (
                      <div
                        className={`pm-stage ${snapshot.isDraggingOver ? 'pm-stage--dragging-over' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <h3>{stage}</h3>
                        {canAddTasks && (
                          <button className="pm-add-task-button" onClick={() => addTask(stage)}>Add Task</button>
                        )}
                        {stages[stage].map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <div
                                className="pm-task"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {task.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </div>
        )}

        {/* Project Controller Section */}
        {selectedRole === roles.PROJECT_CONTROLLER && (
          <div className="pm-fullpage">
            <h2>Project Controller Section</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="pm-stages">
                {Object.keys(stages).map((stage) => (
                  <Droppable key={stage} droppableId={stage}>
                    {(provided, snapshot) => (
                      <div
                        className={`pm-stage ${snapshot.isDraggingOver ? 'pm-stage--dragging-over' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <h3>{stage}</h3>
                        {stages[stage].map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <div
                                className="pm-task"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {task.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectManagementPage;
