import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; 
import { useForm } from "react-hook-form";
import { GetDemandDecisionSchadule, UpdateDemandSchaduleEntry } from "../../_redux/actions/SecondarySales/FundAction";
import { FaSave } from "react-icons/fa";

const DemandDecisionSchaduleEntry = () => {
  const history = useHistory();
  const workingdayList = useSelector((state) => state.fund.demandschadule);

  const { register, handleSubmit, errors, setValue, setFocus, getValues, reset } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetDemandDecisionSchadule());
  }, [dispatch]);

  useEffect(() => {
    if (workingdayList && workingdayList.schaduleId) {
      setValue("startTime_H", workingdayList.startTime_H);
      setValue("startTime_M", workingdayList.startTime_M);
      setValue("endTime_H", workingdayList.endTime_H);
      setValue("endTime_M", workingdayList.endTime_M);
    }
  }, [workingdayList, setValue]);

  const handleUpdate = () => {
    confirmAlert({
      title: "Confirm To Update",
      message: `Are you sure to Update this schedule..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleUpdateSubmit(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleUpdateSubmit = () => {
    const updatedSchedule = {
      startTime_H: getValues("startTime_H"),
      startTime_M: getValues("startTime_M"),
      endTime_H: getValues("endTime_H"),
      endTime_M: getValues("endTime_M"),
    };
   dispatch(UpdateDemandSchaduleEntry(updatedSchedule.startTime_H,updatedSchedule.startTime_M, updatedSchedule.endTime_H, updatedSchedule.endTime_M));
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-2">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Demand Decision Schedule</h3>
            </div>
          </div>
        </div>

        <hr />

        <div className="row" id="id">
          <div className="col-12">
            <div className="d-flex flex-column">
              {workingdayList && workingdayList.schaduleId && (
                <div key={workingdayList.schaduleId} className="schedule-entry mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-end w-100">
                      <button
                        className="btn btn-primary ml-auto"
                        onClick={handleUpdate}
                      >
                        <FaSave /> Update Schedule
                      </button>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="row">
                      {/* Start Time (H) */}
                      <div className="col-md-6 mb-2">
                        <label>Opening Time (24 Hour Format)</label>
                        <input
                          type="number"
                          name="startTime_H"
                          ref={register({ required: true })}
                          className="form-control"
                        />
                      </div>

                      {/* Start Time (M) */}
                      <div className="col-md-6 mb-2">
                        <label>Opening Time (Minute)</label>
                        <input
                          type="number"
                          name="startTime_M"
                          ref={register({ required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      {/* End Time (H) */}
                      <div className="col-md-6 mb-2">
                        <label>Closing Time (24 Hour Format)</label>
                        <input
                          type="number"
                          name="endTime_H"
                          ref={register({ required: true })}
                          className="form-control"
                        />
                      </div>

                      {/* End Time (M) */}
                      <div className="col-md-6 mb-2">
                        <label>Closing Time (Minute)</label>
                        <input
                          type="number"
                          name="endTime_M"
                          ref={register({ required: true })}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemandDecisionSchaduleEntry;
