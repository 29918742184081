import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  DeleteGeneralSalesOrder,
  EditDateGet,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import RemoteSalesDetails from "./RemoteSalesDetails";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import Select from "react-select";

import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { GetImsTrackingSystem } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const RemoteSalesListGeneral = () => {

  const history = useHistory();
  const isLoading = useSelector((state) => state.remoteSales.isLoading);
  const demandlist = useSelector((state) => state.remoteSales.demandlist);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});


  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  useEffect(() => {
    if (defultTerritory?.value && defaultArea?.value ) {
      dispatch(getSalesList(defaultArea?.value, defultTerritory?.value, salesType));
    }
  }, [defultTerritory,defaultArea]);



  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const confirmDelete = (id) => {
    console.log('id', id);
    dispatch(DeleteGeneralSalesOrder(id, selectArea));
    dispatch(GetImsTrackingSystem("general-sales/list", "delete", "mac", id));
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: <span className="confirm-alert-title">Confirm To Delete</span>,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
          className: "approve-yes-btn",
        },
        {
          label: "No",
          className: "approve-no-btn",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };



  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));

    history.push(`/remoteSales/general-sales/edit/${item.orderId}/${item.custId}/${item.custName}`);
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-2">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 >General Sales Demand Report</h3>
            </div>
          </div>
          
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>

        <div className="form-group row p-4">
           <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>
          <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Territory</label>
              <Form.Group as={Col} controlId="formGridState"> 
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                  dispatch(getSalesList(selectArea, option?.value, salesType));

                }}
                defaultValue={defultTerritory}
              />
              </Form.Group>
            </div>

        </div>

        <div className="row" id="id" ref={inputField}>
          <div className="react-bootstrap-table table-responsive border-0 pl-5">

            <div
              className="shortstickyTable"
              style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table">
                <thead>
                  <tr>
                    <th scope="col">ORDER CODE</th>
                    <th scope="col">CUSTOMER ID</th>
                    <th scope="col">CUSTOMER NAME</th>
                    <th scope="col">SALES TYPE</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {demandlist?.length > 0 &&
                    demandlist.map((item, index) => (
                      <tr>
                        <td>{item.orderCode}</td>
                        <td>{item.custId}</td>
                        <td>{item.custName}</td>
                        <td>{item.salesType == "D" ? "Damage" : "General"}</td>
                        <td>{numberFormatDecemal(item.totalQuenty)}</td>
                        <td>
                          {" "}
                          <Link onClick={() => itemDetail(item)} title="Details">
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>

                          <Link onClick={() => gotoEditPage(item)} title="Edit">
                            <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                          </Link>

                          <a
                            href
                            onClick={(id) => handleDeleteItem(item.orderId)}
                            title="Delete"
                          >
                            <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning">
                Sorry ! Sales List Not Found.
              </div>
            )}
            
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        
      </div>
    </>
  );
};

export default RemoteSalesListGeneral;
