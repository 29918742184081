import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
    delteProductList,
    getProductList,
    productSelecteditem,
    itemAddInput,
    productQuantity,
    InputHandle,
    salesSubmit,
    getCustomerBalance,
    cleanRemoteSalesData,
    GetDamageCustomerbyUnit
} from "../../_redux/actions/RemoteSales/BreadRemoteSalesAction";
import {
    getAreaListByDivision,
    getAreaListByDivisionEntry,
    getDivisionListByZone,
    getDivisionListByZoneEntry,
    getTerritory,
    getTerritoryEntry,
    getZoneList,
    getZoneListEntry
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { productSubmitStausRemove } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { damagedemandSubmit } from "../../_redux/actions/RemoteSales/DamageDemandAction";
import { clearFieldData, clearFieldForDamage, damageSubmit, DemageQuantity, getDamageProduct, GetDamageType, productDemageQuantityNew, productSubmitStausRemoveForDamage, SattlementdamageSubmit } from "../../_redux/actions/RemoteSales/DamageAction";
import { format, subDays } from 'date-fns';
import DatePicker from "react-datepicker";
import { dateTimeConvertLongStringForDate } from "../../../../../domains/CCO/utils/DateHelper";

const SattlementDamageEntry = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    const submitData = useSelector((state) => state.remoteSalesbread.submitData);
    const itemDataInput = useSelector((state) => state.remoteSalesbread.itemDataInput);
    const distributorList = useSelector((state) => state.remoteSalesbread.damageCustomerbyUnit);

    const productList = useSelector((state) => state.damage.productList);
    const statusProduct = useSelector((state) => state.damage.productSubmitStatus);
    console.log('statusProduct :>> ', productList);

    const productSubDetails = useSelector((state) => state.damage.productSubDetails);
    const damageType = useSelector((state) => state.damage.damageType);
    const productQuantity = useSelector((state) => state.damage.productQuantity);
    const productCost = useSelector((state) => state.damage.productCost);
    console.log('productList', productList);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [terriory, setTerritory] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [disabled, setDisabled] = useState(false);
    const [salesType, setSalesType] = useState(1);
    const [distribType, setDistribType] = useState(null);
    const [selectDamageType, setSelectDamageType] = useState(null);
    const [selectDistributor, setSelectDistributor] = useState(null);
    const [selectDamageTypeName, setSelectDamageTypeName] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        if (statusProduct) {
            dispatch(productSubmitStausRemoveForDamage());
            dispatch(clearFieldForDamage());
            dispatch(GetDamageType(selectDistributor, startDate, toDate));
            dispatch(getDamageProduct(salesType));
            setSelectDamageType(null);
            setValue('damageType', '');
            // setTimeout(() => { 
            //     history.go(0) 
            // }, 3000);
        }
        dispatch(getDamageProduct(salesType));
    }, [statusProduct]);

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        // console.log('user.employeeType :>> ', user.employeeType);
        // setSalesType(user.employeeType);
        // setDistribType(user.employeeType);
        dispatch(getDamageProduct(salesType));
        dispatch(clearFieldForDamage());

    }, [])

    const changeText = (name, value) => {
        dispatch(itemAddInput(name, value));
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };
    useEffect(() => {
        initialData();
    }, []);

    function productSelected(productItem) {
        dispatch(productSelecteditem(productItem));
    }
    const delteItem = (itemId) => {
        dispatch(delteProductList(itemId));
    };
    const changeProductQuantity = (name, value) => {
        dispatch(productDemageQuantityNew(name, value));
    };
    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };
    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const initialData = async () => {
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }
    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }
    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };
                options.push(itemData);
            });
        }
        return options;
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const getSalesValueType = (name, value) => {
        console.log('value', value);
        setSalesType(value);
        dispatch(GetDamageCustomerbyUnit(selectArea, value));
        dispatch(getDamageProduct(value));
    }

    const Demo = [
        { value: "1", label: "Value" },
        { value: "2", label: "Volume" },
    ];

    const onSubmit = async (e) => {
        console.log('selectDamageType :>> ', selectDamageType);
        if (selectZone == null) {
            alert("Please select Zone");
            return false;
        } else if (selectDivision == null) {
            alert("Please select Division");
            return false;
        } else if (selectArea == null) {
            alert("Please select Area");
            return false;
        } else if (selectTerritory == null) {
            alert("Please select Territory");
            return false;
        } else if (selectTerritory == null) {
            alert("Please select Territory");
            return false;
        } else if (itemDataInput.intDistributorId == "") {
            alert("Please select Distribution");
            return false;
        } else if (selectDamageType == null || selectDamageType == "") {
            alert("Please select Damage Type");
            return false;
        }

        // setDisabled(true);
        dispatch(SattlementdamageSubmit(submitData, productList, selectZone, selectDivision, selectArea, selectTerritory, selectDamageType, dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate)));
        // dispatch(clearFieldData());

        // setValue('damageType', '')
    };

    console.log('productSubDetails', productSubDetails);


    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState('productName');

    //For Sorting
    const handleSort = (column) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortedColumn(column);
            setSortOrder("asc");
            setSearchTerm("");
        }
    };

    // Sorting logic
const sortedList = useMemo(() => {
    if (!Array.isArray(productList)) {
        return [];
    }

    const filteredList = productList.filter((item) => {
        const itemNameLower = String(item.productName).toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        return !searchTermLower || itemNameLower.includes(searchTermLower);
    });

    const sorted = filteredList.sort((a, b) => {
        if (sortOrder === 'asc') {
            if (a[sortedColumn] === b[sortedColumn]) {
                return a.damages - b.damages || a.serial - b.serial;
            } else {
                return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
            }
        } else {
            if (a[sortedColumn] === b[sortedColumn]) {
                return b.damages - a.damages || b.serial - a.serial;
            } else {
                return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
            }
        }
    });

    return sorted;
}, [productList, searchTerm, sortOrder, sortedColumn]);


    // Date

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    //const currentMonth = 4 ;

    let quarterStartDate = new Date();
    let quarterEndDate = new Date();

    const initialStartDate = quarterStartDate;
    const initialEndDate = quarterEndDate;

    const [startDate, setStartDate] = useState(initialStartDate);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeNew = (date) => {
        setIsOpen(!isOpen);
        setStartDate(date);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const [cuDate, setCuDate] = useState(initialEndDate);
    const [istoOpen, setToIsOpen] = useState(false);

    const handleChangeCuDate = (date) => {
        setToIsOpen(!istoOpen);
        setCuDate(date);
    };

    const handleClickCuDate = (e) => {
        e.preventDefault();
        setToIsOpen(!istoOpen);
    };

    useEffect(() => {
        setStartDate(initialStartDate);
        setCuDate(initialEndDate);
    }, []);



    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3 ">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <h3 className="mb-0 pb-0">Sattlement Damage Entry</h3>
                            </div>
                        </div>
                        {/* Check sales type  0 for all 1 for Biscuite and 3 for Bread */}
                        <SimpleSalesType
                            distribType={distribType}
                            salesType={salesType}
                            getSalesValue={getSalesValueType}
                        />
                    </div>
                    <hr></hr>

                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Zones</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={zoneDropdownData()} />}
                                    rules={{ required: false }}
                                    name="zoneData"
                                    register={register}
                                    // value={selectZone}
                                    value=""
                                    isClearable
                                    onChange={(option) => {
                                        divisionList(option?.value);
                                        setSelectZone(option?.value);
                                        setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("intDistributorId", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Division</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={divisionDropdownData()} />}
                                    rules={{ required: false }}
                                    name="divisionData"
                                    register={register}
                                    value=""
                                    isClearable
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        // setType(option.value)
                                        getAreaData(option?.value);
                                        setSelectDivision(option?.value);
                                        setValue("areaData", "");
                                        setValue("intDistributorId", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Region</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={areaDropdownData()} />}
                                    rules={{ required: false }}
                                    name="areaData"
                                    register={register}
                                    value=""
                                    isClearable
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        getTerritoryData(option?.value)
                                        setSelectArea(option?.value);
                                        setValue("intDistributorId", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Territory</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={territoryDropdownData()} />}
                                    rules={{ required: false }}
                                    name="territory"
                                    register={register}
                                    value=""
                                    isClearable
                                    // value={CertificateMasterInput.isActive}
                                    onChange={(option) => {
                                        setSelectTerritory(option?.value);
                                        dispatch(GetDamageCustomerbyUnit(option?.value, salesType));
                                        setValue("intDistributorId", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        {/* <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label"> From Date</label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Deadweight"
                                    name="fromDate"
                                    value={startDate}
                                    className="fromStyle"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label"> To Date</label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Todate"
                                    name="todate"
                                    className="fromStyle"
                                    onChange={handleChangeTodate}
                                    
                                />
                            </Form.Group>
                        </div> */}

                        <div className="col-lg-2">
                            <label className="form-label mARBOTTOMREMOVE">From Date</label>
                            <button className="example-custom-input" onClick={handleClick}>
                                {format(startDate, 'dd-MM-yyyy')}{' '}
                                <i className="mt-1 fa fa-calendar calendardesign" aria-hidden="true"></i>
                            </button>
                            {isOpen && (
                                <DatePicker
                                    selected={startDate}
                                    inline
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="datepickerstyle"
                                    onChange={handleChangeNew}
                                />
                            )}
                        </div>

                        <div className="col-lg-2">
                            <label className="form-label mARBOTTOMREMOVE">To Date</label>
                            <button className="example-custom-input" onClick={handleClickCuDate}>
                                {format(cuDate, 'dd-MM-yyyy')}{' '}
                                <i className="mt-1 fa fa-calendar calendardesign" aria-hidden="true"></i>
                            </button>
                            {istoOpen && (
                                <DatePicker
                                    selected={cuDate}
                                    inline
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="datepickerstyle"
                                    onChange={handleChangeCuDate}
                                />
                            )}
                        </div>





                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Distributor</label>
                                <RHFInput
                                    as={<Select options={distributorList} />}
                                    rules={{ required: false }}
                                    name="intDistributorId"
                                    register={register}
                                    value=""
                                    // value={itemDataInput.intDistributorId}
                                    onChange={(option) => {
                                        setSelectDistributor(option?.value);
                                        inputChangeHandle("distributorName", option.label);
                                        inputChangeHandle("intDistributorId", option.value);
                                        dispatch(getCustomerBalance(option.value));
                                        dispatch(GetDamageType(option.value, dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate)));

                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Damage Type</label>
                                <RHFInput
                                    className="formSelect pt-0 "
                                    as={<Select options={damageType} />}
                                    rules={{ required: false }}
                                    name="damageType"
                                    register={register}
                                    placeholder="Select Damage type"
                                    onChange={(option) => {
                                        setSelectDamageType(option.value)
                                        inputChangeHandle("billName", option?.label);
                                        setSelectDamageTypeName(option?.label)
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                    </div>
                </div>
                <div className="container-fluid mb-3 containerFixed">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="paid__widget three">
                                <div className="widget__left">
                                    <p>Total Product Value</p>
                                </div>
                                <div className="widget__right">
                                    <h4>{productCost.toFixed(2)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="paid__widget four">
                                <div className="widget__left">
                                    <p>Total Quantity(Pieces)</p>
                                </div>
                                <div className="widget__right">
                                    <h4>{productQuantity}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-custom gutter-b pl-5 pr-5 mb-5">

                    {/* {productList.length > 0 ? (
                        <div className="searchBox" >
                            <form className="form mt-2" style={{ backgroundColor: 'skyblue', float: 'right' }}>
                                <span>
                                    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                                        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <input
                                    className="input"
                                    placeholder="Search Product Name"
                                    required=""
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </form>

                        </div>
                    ) : null} */}





                    {productList.length > 0 ? (
                        <div className="row" id="id" >
                            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
                                <div>
                                    <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" >
                                        <thead className="bg-white" >
                                            <tr >
                                                <th onClick={() => handleSort("productId")} >PRODUCT ID
                                                    {sortedColumn === "productId" && (
                                                        <i className={`fa ${sortOrder === "asc" ? "fa-sort-up" : "fa-sort-down"}`} />
                                                    )}
                                                </th>
                                                <th scope="col">PRODUCT NAME</th>
                                                <th scope="col">PRICE</th>
                                                <th scope="col">CATEGORY</th>
                                                <th scope="col">QUANTITY</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productList?.length > 0 &&
                                                productList?.map((item, index) => (
                                                    <>
                                                        <tr >
                                                            <td>{item.productId}</td>
                                                            <td>{item.productName}</td>
                                                            <td>{item.itemPrice}</td>
                                                            <td>{selectDamageTypeName}</td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        autocomplete="off"
                                                                        className="formHeight"
                                                                        type="number"
                                                                        placeholder="Quantity"
                                                                        name="itemName"
                                                                        value={item.itemQuentity}
                                                                        onChange={(e) =>
                                                                            changeProductQuantity(item, e.target.value)
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>

                                                    </>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <button
                                    type="submit"
                                    // disabled={disabled}
                                    className="btn btn-primary 
                                    btn-sm float-right text-center 
                                    custome-addnew-btn item-add-save mb-5"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </form>
        </>
    );
};

export default SattlementDamageEntry;
