import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { getUnApprovedPrimarySalesDemandAllZone, getunapprovedPrimarySalesDemandAllZoneExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaDownload, FaSearch } from "react-icons/fa";

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import DotLoading from "../../../../master/spinner/DotLoading";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const UnApprovedPrimarySalesAllZone = () => {

  const history = useHistory();
  const isProductLoading = useSelector((state) => state.delivery.salesskuLoading);
  const productReport = useSelector((state) => state.delivery.salessoWiseProduct);
  const skuQuantity = useSelector((state) => state.delivery.salesskuQuantity);
  const TotalValueDel = useSelector((state) => state.delivery.salesTotalValueDel);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState(1);
  const componentRef = useRef();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});


  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  });

  const [reportList, setReportList] = useState([
    {
      id: 1,
      type: 'Delivery Date'
    },
    {
      id: 2,
      type: 'Demand Confirm Date'
    }
  ]);

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";


    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }


  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);

  const handleListAction = () => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    dispatch(getUnApprovedPrimarySalesDemandAllZone(conFromDate, conToDate, selectZone, selectDivision, selectArea, selectTerritory, type));
  }

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);



  const hanleExcelClick = async () => {

    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;

    setLoading(true);
    setError(null);

    try {
      const excelClick = await getunapprovedPrimarySalesDemandAllZoneExcel(
        conFromDate,
        conToDate,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory, 1
      );
      console.log('excelClick', excelClick)

      const current = new Date().toISOString().slice(0, 10);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');

      worksheet.columns = [
        { header: 'Zone ID', key: 'zoneid', width: 10 },
        { header: 'Zone Name', key: 'zonename', width: 20 },
        { header: 'Division ID', key: 'divisionid', width: 10 },
        { header: 'Division Name', key: 'divisionname', width: 20 },
        { header: 'Region ID', key: 'areaid', width: 10 },
        { header: 'Region Name', key: 'areaname', width: 20 },
        { header: 'Territory ID', key: 'territoryId', width: 10 },
        { header: 'Territory Name', key: 'territoryName', width: 20 },

        { header: 'Distributor ID', key: 'distribID', width: 10 },
        { header: 'Distributor Name', key: 'distribName', width: 20 },
        { header: 'Dis Point ID', key: 'disPointid', width: 10 },
        { header: 'Dis Point Name', key: 'disPointName', width: 20 },

        { header: 'Product Catagories', key: 'productCategoryName', width: 20 },
        { header: 'Product ID', key: 'productID', width: 20 },
        { header: 'Product Name', key: 'productName', width: 40 },

        { header: 'Brand Name', key: 'brandName', width: 20 },
        { header: 'Pack Type Name', key: 'packTypeName', width: 20 },

        { header: 'Product Price', key: 'productPrice', width: 20 },
        { header: 'Product Qty', key: 'productQty', width: 20 },
        { header: 'Product Total Price', key: 'productTPrice', width: 20 },



      ];

      worksheet.addRows(excelClick?.data || []);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, `Primary Sales(All Zone) ${current}.xlsx`);
    } catch (error) {
      console.error('Error:', error);
      setError('Network Error');
    } finally {
      setLoading(false);
    }
  };


  const [searchQuery, setSearchQuery] = useState("");


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  const filteredReport = productReport?.filter((item) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      item.productID.toString().toLowerCase().includes(lowerCaseQuery)

    );
  });


  const totalQty = filteredReport && filteredReport.reduce((sum, item) => sum + item.productQty, 0);




  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-2">
          <div className="col-md-9">
            <div className="mt-2 p-2">

              <h3 className="mb-0 pb-0">UnApproved Primary Sales Report Sku Wise</h3>
            </div>
          </div>
          <div className="col-md-3">

            <div className="mt-2 p-2 float-right">
              {/* <Button
                size="sm"
                onClick={hanleExcelClick}
                disabled={loading}
                className={loading ? 'bg-danger' : ''}
              >
                <FaDownload /> {loading ? 'Loading...' : 'Download'}
              </Button>
              {error && <div>Error: {error}</div>} */}
              <DownloadDropdown excelname='Primaru Sales (All Zone)' />
            </div>
          </div>


        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={`${skuDate.fromDate}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option)
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}

                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option)
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">

            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}

                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option)
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)

                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-md mt-3 mb-3 ml-4">

            <Button className="btn" onClick={() => handleListAction()}><FaSearch /> Show Report</Button>
          </div>

        </div>
        <div>

          <div className="row" id="id" ref={inputField}>

            {isProductLoading && <DotLoading />}


            <div className="container-fluid fullcardbg">

              {
                filteredReport?.length > 0 &&
                <div className="search-bar" >

                  <div className="search-input-container mb-2 mt-3 mr-5">

                    <input
                      type="text"
                      placeholder={`${filteredReport?.length} Records Found - Search by Product Id`}
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="search-input"
                    />
                    <span className="search-icon">🔍</span>
                  </div>

                  <div className="mb-2 text-center mr-5" style={{ padding: '5px', borderRadius: '8px', background: 'linear-gradient(135deg, #6e7a8d, #2d3a45)', color: '#fff', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                      <div style={{ fontSize: '12px', letterSpacing: '1px' }}>
                        Total Product Quantity
                      </div>
                      <div style={{ fontSize: '12px', fontWeight: '700', color: '#ffcc00' }}>
                        {numberFormatDecemal(totalQty)}
                      </div>
                    </div>
                  </div>


                </div>

              }



              <div className="row">
                <div className="custom-table-responsive-category">
                  <table className="table table-striped custom-table" id="table-to-xls" ref={componentRef}>
                    {
                      filteredReport?.length > 0 &&
                      <thead>
                        <tr>
                          <th className="text-center sticky-header">Inv</th>
                          <th className="text-center sticky-header">Zone</th>
                          <th className="text-center sticky-header">Division</th>
                          <th className="text-center sticky-header">Region</th>
                          <th className="text-center sticky-header">Territory</th>
                          <th className="text-center sticky-header">Distributor ID</th>
                          <th className="text-center sticky-header">Distributor Name</th>
                          <th className="text-center sticky-header">Distributor Point ID</th>
                          <th className="text-center sticky-header">Distributor Point</th>
                          <th className="text-center sticky-header">Product ID</th>
                          {/* <th className="text-center sticky-header">Product Name</th> */}
                          <th className="text-center sticky-header">Product Value</th>
                          <th className="text-center sticky-header">Product Quantity</th>
                          <th className="text-center sticky-header">Total Value</th>
                        </tr>
                      </thead>
                    }

                    <tbody>
                      {filteredReport?.length > 0 &&
                        filteredReport.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{item.invId}</td>
                            <td className="text-center">{item.zonename}</td>
                            <td className="text-center">{item.divisionname}</td>
                            <td className="text-center">{item.areaname}</td>
                            <td className="text-center">{item.territoryName}</td>
                            <td className="text-center">{item.distribID}</td>
                            <td >{item.distribName}</td>
                            <td className="text-center">{item.disPointid}</td>
                            <td className="text-center">{item.disPointName}</td>
                            <td className="text-center">{item.productID}</td>
                            {/* <td className="text-center">{item.productName}</td> */}
                            <td className="text-center">{parseFloat(item.productPrice).toFixed(2)}</td>
                            <td className="text-center">{item.productQty}</td>
                            <td className="text-center">{parseFloat(item.productTPrice).toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


          </div>

        </div>

      </div>
    </>
  );
};

export default UnApprovedPrimarySalesAllZone;
